<template>
    <div>
        <!-- Start row  -->
        <div class="card mb-2 p-3" id="ordersTable"
            data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
            <h6 class="text-primary  border-bottom pb-1">Summary</h6>
            <div class="row g-2">
                <!-- start: first row -->
                <div class="col-6 col-sm-3" role="button">
                    <div class="card overflow-hidden border-top border-bottom border-3 border-primary">
                        <div class="card-body position-relative" :key="daily_analysis?.products">
                            <h6>Total Expenses</h6>
                            <count-up class="display-4 fs-3 fw-normal font-sans-serif text-primary"
                                :end-val="summary.totalExpense" start-val="0"></count-up>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-3" role="button">
                    <div class="card overflow-hidden border-top border-bottom border-3 border-warning">
                        <div class="card-body position-relative" :key="daily_analysis?.users">
                            <h6>Monthly Total</h6>
                            <count-up class="display-4 fs-3 fw-normal font-sans-serif text-warning"
                                :end-val="summary.totalMonthly" start-val="0"></count-up>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-3" role="button">
                    <div class="card overflow-hidden border-top border-bottom border-3 border-info">
                        <div class="card-body position-relative">
                            <h6>Weekly Expense</h6>
                            <count-up class="display-4 fs-3 fw-normal font-sans-serif text-info"
                                :end-val="summary.totalWeekly" start-val="0"></count-up>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-sm-3" role="button">
                    <div class="card overflow-hidden border-top border-bottom border-3 border-success">
                        <div class="card-body position-relative">
                            <h6>Today's Expense</h6>
                            <count-up class="display-4 fs-3 fw-normal font-sans-serif text-success"
                                :end-val="summary.totalDaily" :start-val="0"></count-up>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-2">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="card mb-3" id="ordersTable"
                    data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
                    <div class="card-header">
                        <div class="row flex-between-center border-bottom pb-2">
                            <div class="col-4 col-sm-auto align-items-center pe-0">
                                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                                    <i class="fa fa-inbox-out text-warning"></i>
                                    Filters
                                </h5>
                                <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                                    Manage all expenditures
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-2 py-0">
                        <div class="card-body position-relative py-2">
                            <h6>Property</h6>
                            <select class="form-select d-inline" aria-label="Select property" @change="PropertySelected"
                                v-model="formData.property">
                                <option value="0" selected="">All properties</option>
                                <option v-for="(item, i) in propertys" :key="i" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                        <div class=" pb-auto" style="min-height: 100%;
          height: 100%;">
                        </div>
                    </div>
                    <div class="card-footer">
                        <!-- footer content  -->
                    </div>
                </div>


            </div>
            <div class="col-9 col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div class="card mb-3" id="ordersTable"
                    data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
                    <div class="card-header">
                        <div class="row flex-between-center border-bottom pb-1">
                            <div class="col-4 col-sm-auto align-items-center pe-0">
                                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                                    <i class="fa fa-inbox-out text-warning"></i>
                                    Expenses
                                </h5>
                                <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                                    Manage all expenditures
                                </p>
                            </div>
                            <div class="col-8 col-sm-auto ms-auto text-end ps-0">
                                <div id="orders-actions" class="d-flex">
                                    <div class="search-box h1 me-1">
                                        <input class="form-control search-input fuzzy-search" type="text"
                                            placeholder="Search..." aria-label="Search" v-model="searchValue" />
                                        <span class="fas fa-search search-box-icon"></span>
                                    </div>
                                    <button @click="showAddForm" type="button"
                                        class="btn btn-falcon-warning btn-sm h2 ms-2 rounded-pill">
                                        <i class="fas fa-plus"></i> New
                                    </button>
                                    <button @click="forceReload" class="btn btn-falcon-warning btn-sm h2 ms-2 rounded-pill">
                                        <i class="fa-solid fa-arrows-rotate"></i> Refresh
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Start add form  -->
                        <div v-if="addForm">
                            <div class="modal-content border-0">
                                <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                                        @click="hideAddForm" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body p-0">
                                    <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                                        <h4 class="mb-1" id="staticBackdropLabel">{{ formData.id ? 'Edit' : 'Add' }} Expense
                                        </h4>
                                    </div>
                                    <div class="my-3 mx-2">
                                        <div class="row">
                                            <div class="mb-3 col-6">
                                                <label class="col-form-label" for="recipient-name">Select Property</label>
                                                <Select2 v-if="!formData.id" class="d-block border"
                                                    style="width: 100%; max-height: 38px !important"
                                                    placeholder="Select a property..." v-model="formData.property_id"
                                                    :options="properties" @select="mySelectEvent($event)" :class="v$.formData.property_id.$dirty &&
                                                        v$.formData.property_id.$invalid
                                                        ? `is-invalid`
                                                        : v$.formData.property_id.$dirty
                                                            ? `is-valid`
                                                            : ``
                                                        " />
                                                <input v-else disabled class="form-control"
                                                    v-model="formData.property_name">
                                                <div v-for="error of v$.formData.property_id.$errors" :key="error.$uid"
                                                    class="invalid-feedback" style="color: red">
                                                    This field is invalid
                                                </div>
                                            </div>
                                            <div class="mb-3 col-6">
                                                <label class="col-form-label" for="recipient-name">{{
                                                    formData.id ? 'Selected' : 'Select' }}
                                                    Unit</label>
                                                <Select2 v-if="!formData.id" class="d-block border"
                                                    style="width: 100%; max-height: 38px !important"
                                                    placeholder="Select a unit..." v-model="formData.unit_id"
                                                    :options="property_units" :class="v$.formData.unit_id.$dirty &&
                                                        v$.formData.unit_id.$invalid
                                                        ? `is-invalid`
                                                        : v$.formData.unit_id.$dirty
                                                            ? `is-valid`
                                                            : ``
                                                        " />
                                                <input v-else disabled class="form-control" v-model="formData.unit_name">
                                                <div v-for="error of v$.formData.unit_id.$errors" :key="error.$uid"
                                                    class="invalid-feedback" style="color: red">
                                                    This field is invalid
                                                </div>
                                            </div>
                                            <div class="mb-3 col-6">
                                                <label class="col-form-label" for="recipient-name">Amount(Ksh)</label>
                                                <input class="form-control" id="recipient-name" type="text"
                                                    v-model="formData.amount" :class="v$.formData.amount.$dirty &&
                                                        v$.formData.amount.$invalid
                                                        ? `is-invalid`
                                                        : v$.formData.amount.$dirty
                                                            ? `is-valid`
                                                            : ``
                                                        " />
                                                <div v-for="error of v$.formData.amount.$errors" :key="error.$uid"
                                                    class="invalid-feedback" style="color: red">
                                                    This field is invalid
                                                </div>
                                            </div>
                                            <div class="mb-3 col-6">
                                                <label class="col-form-label" for="message-text">Description</label>
                                                <textarea v-model="formData.note" class="form-control"
                                                    id="exampleFormControlTextarea1" rows="3" :class="v$.formData.note.$dirty &&
                                                        v$.formData.note.$invalid
                                                        ? `is-invalid`
                                                        : v$.formData.note.$dirty
                                                            ? `is-valid`
                                                            : ``
                                                        "></textarea>
                                                <div v-for="error of v$.formData.note.$errors" :key="error.$uid"
                                                    class="invalid-feedback" style="color: red">
                                                    This field is invalid
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn me-auto border" @click="hideAddForm" aria-label="Close">
                                                Close
                                            </button>
                                            <button v-if="formData.id"
                                                class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button"
                                                @click="updateResource(resource)">
                                                <span class="fa fa-save me-2" data-fa-transform=""></span><span
                                                    class="d-none d-sm-inline-block ms-1">Update</span>
                                            </button>
                                            <button v-else class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
                                                type="button" @click="saveResource()">
                                                <span class="fa fa-save me-2" data-fa-transform=""></span><span
                                                    class="d-none d-sm-inline-block ms-1">Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <!-- End add form  -->
                            <EasyDataTable v-if="theme == 'dark'" id="tour-step-1" table-class-name="dark-table" alternating
                                buttons-pagination :headers="headers" :items="resources" :search-field="searchField"
                                :search-value="searchValue" :loading="loading">
                                <template #loading>
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </template>
                                <template #item-name="item">
                                    <div class="my-2">
                                        <strong>{{ item.amount }}</strong>
                                        <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
                                    </div>
                                </template>
                                <template #item-created_at="item">
                                    <div class="my-2">
                                        {{ formatDateHuman(item.created_at) }}
                                    </div>
                                </template>

                                <template #item-action="item">
                                    <div class="dropdown font-sans-serif position-static">
                                        <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                                            type="button" id="order-dropdown-0" data-bs-toggle="dropdown"
                                            data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                                            <span class="fas fa-ellipsis-h fs--1"></span>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end border py-0"
                                            aria-labelledby="order-dropdown-0">
                                            <div class="py-2">
                                                <a @click="editItem(item)" class="dropdown-item" role="button"
                                                    data-bs-toggle="modal" data-bs-target="#edit-modal">Edit</a>

                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item text-danger" role="button"
                                                    @click="deleteRecource(item)">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </EasyDataTable>
                            <EasyDataTable v-else id="tour-step-1" table-class-name="customize-table" alternating
                                buttons-pagination :headers="headers" :items="resources" :search-field="searchField"
                                :search-value="searchValue" :loading="loading">
                                <template #loading>
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </template>
                                <template #item-name="item">
                                    <div class="my-2">
                                        <strong>{{ item.amount }}</strong>
                                    </div>
                                </template>
                                <template #item-created_at="item">
                                    <div class="my-2">
                                        {{ formatDateHuman(item.created_at) }}
                                    </div>
                                </template>

                                <template #item-action="item">
                                    <div class="dropdown font-sans-serif position-static">
                                        <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                                            type="button" id="order-dropdown-0" data-bs-toggle="dropdown"
                                            data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                                            <span class="fas fa-ellipsis-h fs--1"></span>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-end border py-0"
                                            aria-labelledby="order-dropdown-0">
                                            <div class="py-2">
                                                <a @click="editItem(item)" class="dropdown-item" role="button"
                                                    data-bs-toggle="modal" data-bs-target="#edit-modal">Edit</a>

                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item text-danger" role="button"
                                                    @click="deleteRecource(item)">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </EasyDataTable>
                            <!-- End Menu items table  -->
                        </div>
                    </div>
                    <div class="card-footer">
                        <!-- footer content  -->
                    </div>
                </div>
            </div>
        </div>
        <!-- End row  -->
    </div>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import CountUp from "vue-countup-v3";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuGroupService from "../services/menu_group.service";
import PropertyUnitSizeService from "../services/property_unit_size.service";
import ExpenseService from "../services/expense.service";
import PropertyUnitService from "../services/property_unit.service";
import PropertyService from "../services/propertys.service";

const now = new Date();

import { ref } from "vue";

const range = ref({
    start: moment().startOf("week").format("YYYY-MM-DD"),
    end: moment().endOf("week").format("YYYY-MM-DD"),
});

export default {
    mixins: [mixins],
    name: "ExpensesUnitView",
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            formData: {
                amount: { required },
                note: { required },
                unit_id: { required },
                property_id: { required },
            },
        };
    },
    components: {
        NavDrawer,
        AppBar,
        CountUp,
        flatPickr,
        FooterComp,
        LoaderOverlay,
        EasyDataTable,
    },
    data() {
        return {
            addForm: false,
            summary: [],
            propertys: [],
            properties: [],
            units: [],
            selected_category: "",
            property_unit_sizes: [],
            productCategories: [],
            groups: [],
            searchValue: "",
            table_keys: 1,
            range: range,
            formData: {
                group_id: "",
                active: 0,
                visible: 1,
                unit_name: "",
                start: moment().startOf("week").format("YYYY-MM-DD"),
                end: moment().endOf("week").format("YYYY-MM-DD"),
                property: 0,
            },
            resources: [],
            orders_table_key: 0,
            searchField: ["id", "name", "price"],
            headers: [
                { text: "#", value: "id", sortable: true, width: 10 },
                { text: "Property Name", value: "property.name", sortable: true },
                { text: "Unit Name", value: "unit.name", sortable: true },
                { text: "Date", value: "created_at", sortable: true },
                { text: "Details", value: "note", sortable: true },
                { text: "Amount", value: "name", sortable: true },
                { text: "Action", value: "action" },
            ],
        };
    },
    async created() {
        this.getData();
        this.getSummary();
        this.getUnits();
        this.getProperties();
    },
    watch: {
        range: {
            handler(newVal, oldVal) {
                this.calendar_key += 1;
                const start = moment(newVal.start).format("YYYY-MM-DD HH:mm:ss");
                const end = moment(newVal.end).format("YYYY-MM-DD HH:mm:ss");
                this.formData.start = start;
                this.formData.end = end;
                this.getAllData();
            },
            deep: true,
        },
    },
    computed: {
        loading() {
            return this.$store.getters.getLoading;
        },
        theme() {
            return localStorage.getItem("theme");
        },
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("user"));
    },
    methods: {
        clearModals() {
            $("#edit-modal").removeClass("fade").hide();
            $("#add-modal").removeClass("fade").hide();
            $(".modal-backdrop").remove();
        },
        showAddForm() {
            this.formData = {};
            this.addForm = true;
        },
        hideAddForm() {
            this.addForm = false;
        },
        getProperties() {
            this.$store.commit("SET_LOADING", true);
            return PropertyService.index().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.propertys = response.data.data;
                        this.properties = response.data.data.map((el) => {
                            return {
                                id: el.id,
                                text: el.name + ' - ' + el.address,
                            };
                        });
                    } else {
                        this.properties = [];
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        mySelectEvent(poperty) {
            console.log(poperty)
            console.log(poperty.id)
            this.getUnits(poperty.id)
        },
        getUnits(id) {
            this.$store.commit("SET_LOADING", true);
            return PropertyService.getUnits(id).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.property_units = response.data.data.map((el) => {
                            return {
                                id: el.id,
                                text: el.name,
                            };
                        });
                        console.log(this.units);
                    } else {
                        this.property_units = [];
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        editItem(item) {
            console.log(item)
            this.formData = item
        },
        async selectProperty(e) {
            this.$store.commit("SET_LOADING", true);
            await this.getItemsByOrder(e.target.value);
            this.$store.commit("SET_LOADING", false);
        },
        categorySelected(e) {
            console.log(e);
            this.getItemsByOrder(e.target.value);
        },
        sortItems(list) {
            return list.sort((a, b) => (a.amount > b.amount ? 1 : -1));
        },
        getMenuCategories() {
            this.$store.commit("SET_LOADING", true);
            return ProductService.getMenuCategories().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.productCategories = response.data.data;

                        this.getItemsByOrder(response.data.data.at(0).id);
                    } else {
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        getItemsByOrder(item_id) {
            this.$store.commit("SET_LOADING", true);
            return CartService.byOrder(item_id).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.resources = this.sortItems(response.data.data);
                        this.$store.commit("SET_LOADING", false);
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        groupSelected(e) {
            this.formData.group_id = e.target.value;
        },
        getPropertyUnitSize() {
            return PropertyUnitSizeService.index().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.property_unit_sizes = response.data.data;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getGroups() {
            return MenuGroupService.index().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.groups = response.data.data;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        toggleShowOnMain() {
            this.formData.show_on_main_page = !this.formData.show_on_main_page;
        },
        toggleActive() {
            this.formData.active = !this.formData.active;
        },
        toggleRoot() {
            this.formData.is_root = !this.formData.is_root;
        },
        saveResource() {
            this.v$.formData.unit_id.$touch();
            this.v$.formData.property_id.$touch();
            this.v$.formData.amount.$touch();
            this.v$.formData.note.$touch();
            console.log(this.v$.formData);
            if (this.v$.formData.$invalid) {
                return;
            }
            this.$store.commit("SET_LOADING", true);
            ExpenseService.store(this.formData).then(
                (response) => {
                    if (response.data.code == 200) {
                        toast.success(response.data.message, {
                            autoClose: 2000,
                        });
                        this.getData();
                        this.clearModals();
                        this.hideAddForm();
                    } else {
                        console.log(response.data.error);
                        toast.error(response.data.error, {
                            autoClose: 2000,
                        });
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    toast.error(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        updateResource(resource) {
            this.v$.formData.amount.$touch();
            this.v$.formData.note.$touch();
            console.log(this.formData);
            if (this.v$.formData.$invalid) {
                return;
            }
            this.$store.commit("SET_LOADING", true);
            ExpenseService.update(this.formData).then(
                (response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        toast.success(response.data.message, {
                            autoClose: 2000,
                        });
                        this.getData();
                        this.clearModals();
                        this.hideAddForm();
                    } else {
                        this.resources = [];
                        console.log(response.data.error);
                        toast.error(response.data.error, {
                            autoClose: 2000,
                        });
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        deleteRecource(resource) {
            let sure = confirm(
                "Are you sure you want to delete? you cannot undo this action."
            );
            if (sure) {
                this.$store.commit("SET_LOADING", true);
                ExpenseService.delete(resource).then(
                    (response) => {
                        console.log(response);
                        if (response.data.code == 200) {
                            toast.success(response.data.message, {
                                autoClose: 2000,
                            });
                            this.getData();
                            $("#edit-modal")
                                .removeClass("fade")
                                .hide();
                            $(".modal-backdrop").remove();
                        } else {
                            console.log(response.data.error);
                            toast.error(response.data.error, {
                                autoClose: 2000,
                            });
                        }
                        this.$store.commit("SET_LOADING", false);
                    },
                    (error) => {
                        console.log(error);
                        this.$store.commit("SET_LOADING", false);
                    }
                );
            }
        },
        getData() {
            this.$store.commit("SET_LOADING", true);
            return ExpenseService.indexUnit().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.resources = response.data.data;
                    } else {
                        this.resources = [];
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        getSummary() {
            this.$store.commit("SET_LOADING", true);
            return ExpenseService.getSummaryUnit().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.summary = response.data.data;
                    } else {
                        this.summary = [];
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
    },
};
</script>
