<template>
  <div>
    
          <div class="card mb-3">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col d-flex align-items-center">
                  <div class="form-check mb-0 d-none d-sm-block"><input class="form-check-input checkbox-bulk-select" type="checkbox" data-bulk-select='{"body":"emails","actions":"emails-actions"}' /></div><button class="btn btn-falcon-default btn-sm ms-sm-1" type="button" onclick="location.reload()"><span class="fas fa-redo"></span></button>
                  <div class="dropdown font-sans-serif"><button class="btn btn-falcon-default text-600 btn-sm dropdown-toggle dropdown-caret-none ms-2" type="button" id="email-filter" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fas fa-sliders-h"></span></button>
                    <div class="dropdown-menu border py-0" aria-labelledby="email-filter">
                      <div class="bg-white dark__bg-dark py-2"><a class="dropdown-item d-flex justify-content-between" href="inbox.html#!">All<span class="fas fa-check" data-fa-transform="down-4 shrink-4"></span></a><a class="dropdown-item" href="inbox.html#!">Unread</a><a class="dropdown-item" href="inbox.html#!">To me</a><a class="dropdown-item" href="inbox.html#!">Flagged</a><a class="dropdown-item" href="inbox.html#!">Mentions</a><a class="dropdown-item" href="inbox.html#!">Attachments</a></div>
                    </div>
                  </div>
                  <div class="border-start ms-3 ps-3 d-none" id="emails-actions">
                    <div class="btn-group btn-group-sm"><button class="btn btn-falcon-default" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Archive"><span class="fas fa-archive"></span></button><button class="btn btn-falcon-default" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><span class="fas fa-trash-alt"></span></button><button class="btn btn-falcon-default" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as unread"><span class="fas fa-envelope"></span></button><button class="btn btn-falcon-default" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Snooze"><span class="fas fa-clock"></span></button></div>
                  </div>
                </div>
                <div class="col-auto"><a class="btn btn-falcon-primary btn-sm" href="compose.html"><span class="fas fa-plus me-1" data-fa-transform="shrink-3"></span>Compose</a></div>
              </div>
            </div>
            <div class="card-body fs--1 border-top border-200 p-0" id="emails">
              <h5 class="fs-0 px-3 pt-3 pb-2 mb-0 border-bottom border-200">Unread</h5>
              <div class="row border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 bg-white dark__bg-dark" data-href="../../app/email/email-detail.html">
                <div class="btn-group btn-group-sm z-index-2 hover-actions end-0 me-3" style="width: 10rem;"><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Archive"><span class="fas fa-archive"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><span class="fas fa-trash-alt"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as read"><span class="fas fa-envelope-open"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Snooze"><span class="fas fa-clock"></span></button></div>
                <div class="col-auto d-none d-sm-block">
                  <div class="d-flex bg-white dark__bg-dark">
                    <div class="form-check mb-0 fs-0"><input class="form-check-input" type="checkbox" id="checkbox-2" data-bulk-select-row="data-bulk-select-row" /></div><span class="fas text-warning fa-star ms-1" data-fa-transform="down-4"></span>
                  </div>
                </div>
                <div class="col col-md-9 col-xxl-10">
                  <div class="row">
                    <div class="col-md-4 col-xl-3 col-xxl-2 ps-md-0 mb-1 mb-md-0">
                      <div class="d-flex position-relative">
                        <div class="avatar avatar-s">
                          <img class="rounded-soft" src="assets/img/team/17.jpg" alt="" />
                        </div>
                        <div class="flex-1 ms-2"><a class="fw-bold stretched-link inbox-link" href="email-detail.html">Diana</a><span class="badge badge-soft-success badge-pill ms-2">NEW</span></div>
                      </div>
                    </div>
                    <div class="col"><a class="d-block inbox-link" href="email-detail.html"><span class="fw-bold">Your Daily Work Summary</span><span class="mx-1">&ndash;</span><span>And they'd probably do a lot of damage to an...</span></a></div>
                  </div>
                </div>
                <div class="col-auto ms-auto d-flex flex-column justify-content-between"><span class="fw-bold">Mar 26</span><span class="fas text-warning fa-star ms-auto mb-2 d-sm-none" data-fa-transform="down-7"></span></div>
              </div>
              <div class="row border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 bg-white dark__bg-dark" data-href="../../app/email/email-detail.html">
                <div class="btn-group btn-group-sm z-index-2 hover-actions end-0 me-3" style="width: 10rem;"><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Archive"><span class="fas fa-archive"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><span class="fas fa-trash-alt"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as read"><span class="fas fa-envelope-open"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Snooze"><span class="fas fa-clock"></span></button></div>
                <div class="col-auto d-none d-sm-block">
                  <div class="d-flex bg-white dark__bg-dark">
                    <div class="form-check mb-0 fs-0"><input class="form-check-input" type="checkbox" id="checkbox-7" data-bulk-select-row="data-bulk-select-row" /></div><span class="far text-300 fa-star ms-1" data-fa-transform="down-4"></span>
                  </div>
                </div>
                <div class="col col-md-9 col-xxl-10">
                  <div class="row">
                    <div class="col-md-4 col-xl-3 col-xxl-2 ps-md-0 mb-1 mb-md-0">
                      <div class="d-flex position-relative">
                        <div class="avatar avatar-s">
                          <img class="rounded-soft" src="assets/img/logos/unsplash.png" alt="" />
                        </div>
                        <div class="flex-1 ms-2"><a class="fw-bold stretched-link inbox-link" href="email-detail.html">Unsplash Team</a></div>
                      </div>
                    </div>
                    <div class="col"><a class="d-block inbox-link" href="email-detail.html"><span class="fw-bold">Get involved for International Women's Day - with link 👩</span><span class="mx-1">&ndash;</span><span>The link below is now clickable for Chrome users...</span></a><a class="d-inline-flex align-items-center border rounded-pill px-3 py-1 me-2 mt-2 inbox-link" href="assets/img/generic/1.jpg" data-gallery="gallery-7"><span class="fas fa-image text-danger" data-fa-transform="grow-4"></span><span class="ms-2">Winter</span></a><a class="d-inline-flex align-items-center border rounded-pill px-3 py-1 me-2 mt-2 inbox-link" href="assets/img/generic/8.jpg" data-gallery="gallery-7"><span class="fas fa-image text-danger" data-fa-transform="grow-4"></span><span class="ms-2">Coffee</span></a></div>
                  </div>
                </div>
                <div class="col-auto ms-auto d-flex flex-column justify-content-between"><span class="fw-bold">Dec 16</span><span class="far text-300 fa-star ms-auto mb-2 d-sm-none" data-fa-transform="down-7"></span></div>
              </div>
              <div class="row border-bottom border-200 hover-actions-trigger hover-shadow py-2 px-1 mx-0 bg-white dark__bg-dark" data-href="../../app/email/email-detail.html">
                <div class="btn-group btn-group-sm z-index-2 hover-actions end-0 me-3" style="width: 10rem;"><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Archive"><span class="fas fa-archive"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><span class="fas fa-trash-alt"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark as read"><span class="fas fa-envelope-open"></span></button><button class="btn btn-light hover-bg-200" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Snooze"><span class="fas fa-clock"></span></button></div>
                <div class="col-auto d-none d-sm-block">
                  <div class="d-flex bg-white dark__bg-dark">
                    <div class="form-check mb-0 fs-0"><input class="form-check-input" type="checkbox" id="checkbox-12" data-bulk-select-row="data-bulk-select-row" /></div><span class="far text-300 fa-star ms-1" data-fa-transform="down-4"></span>
                  </div>
                </div>
                <div class="col col-md-9 col-xxl-10">
                  <div class="row">
                    <div class="col-md-4 col-xl-3 col-xxl-2 ps-md-0 mb-1 mb-md-0">
                      <div class="d-flex position-relative">
                        <div class="avatar avatar-s">
                          <img class="rounded-soft" src="assets/img/logos/goodreads.png" alt="" />
                        </div>
                        <div class="flex-1 ms-2"><a class="fw-bold stretched-link inbox-link" href="email-detail.html">Goodreads</a></div>
                      </div>
                    </div>
                    <div class="col"><a class="d-block inbox-link" href="email-detail.html"><span class="fw-bold">Goodreads Newsletter: March 5, 2019</span><span class="mx-1">&ndash;</span><span>The most anticipated books of spring, a rocking read, and more! Goodreads Spring...</span></a></div>
                  </div>
                </div>
                <div class="col-auto ms-auto d-flex flex-column justify-content-between"><span class="fw-bold">March 5</span><span class="far text-300 fa-star ms-auto mb-2 d-sm-none" data-fa-transform="down-7"></span></div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-between align-items-center"><small>2.29 GB <span class="d-none d-sm-inline-block">(13%)  </span> of 17 GB used</small>
              <div><small>1-12 of 354</small><button class="btn btn-falcon-default btn-sm ms-1 ms-sm-2" type="button" disabled="disabled"><span class="fas fa-chevron-left"></span></button><button class="btn btn-falcon-default btn-sm ms-1 ms-sm-2" type="button"><span class="fas fa-chevron-right"></span></button></div>
            </div>
          </div>

    <div v-for="(resource, i) in resources" :key="i">
      <!-- Start edit modal  -->
      <div
        class="modal fade"
        :id="'edit-modal' + resource.id"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        :aria-labelledby="'edit-modal' + resource.id + 'Label'"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="
                  btn-close btn btn-sm btn-circle
                  d-flex
                  flex-center
                  transition-base
                "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Edit Tenant</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name"
                            >Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            :placeholder="resource.name"
                            v-model="formData.name"
                            :class="
                              v$.formData.name.$dirty &&
                              v$.formData.name.$invalid
                                ? `is-invalid`
                                : v$.formData.name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Email</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            :placeholder="resource.email"
                            v-model="formData.email"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Phone</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            disabled
                            :placeholder="resource.mobile_no"
                            v-model="formData.color"
                          />
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="
                          fa-inverse fa-stack-1x
                          text-primary
                          fas
                          fa-align-left
                        "
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >KRA PIN:</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >Log Book</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >National ID</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <label class="col-form-label" for="message-text"
                          >Other details</label
                        >
                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.detail"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            :placeholder="formData.detail"
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Vehicles</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li
                      class="nav-item me-2 me-lg-0"
                      v-for="(vehicle, i) in resource.vehicles"
                      :key="i"
                    >
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.active || resource.active
                            ? `bg-success text-white`
                            : ``
                        "
                        ><span class="fas fa-dot-circle me-2"></span
                        >{{ vehicle.make }}, {{ vehicle.model }} -
                        {{ vehicle.reg_no }}
                      </a>
                    </li>
                  </ul>
                  <hr />
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        ><span class="fa fa-plus me-2"></span>Add Vehicle
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleRoot"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.is_root || resource.is_root
                            ? `bg-success text-white`
                            : ``
                        "
                      >
                        <span class="fas fa-download me-2"></span>Download KYCs
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        class="nav-link nav-link-card-details"
                        role="button"
                        @click="toggleShowOnMain"
                        :class="
                          formData.show_on_main_page ||
                          resource.show_on_main_page
                            ? `bg-success text-white`
                            : ``
                        "
                        ><span class="fas fa-align-left me-2"></span>View
                        History</a
                      >
                    </li>
                    <li class="nav-item me-2 me-lg-0 mt-8">
                      <a
                        class="
                          nav-link nav-link-card-details
                          bg-danger
                          text-white
                        "
                        role="button"
                        @click="deleteRecource(resource)"
                        ><span class="fas fa-paperclip me-2"></span>Delete</a
                      >
                    </li>
                    <li class="nav-item me-2 me-lg-0 mt-2">
                      <a
                        class="
                          nav-link nav-link-card-details
                          bg-primary
                          text-white
                        "
                        role="button"
                        @click="updateResource(resource)"
                        ><span class="fa fa-align-left me-2"></span>Update
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->
    </div>

    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add new tenant</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name"
                            >Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g John Doe"
                            v-model="formData.name"
                            :class="
                              v$.formData.name.$dirty &&
                              v$.formData.name.$invalid
                                ? `is-invalid`
                                : v$.formData.name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Email</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.ge johndoe@gmail.com"
                            v-model="formData.email"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Phone</label
                          >
                          <input
                            class="form-control"
                            id="recipient-home"
                            type="text"
                            placeholder="e.g +25471234567"
                            v-model="formData.mobile_no"
                          />
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="
                          fa-inverse fa-stack-1x
                          text-primary
                          fas
                          fa-align-left
                        "
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >KRA PIN:</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >Log Book</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >National ID</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <label class="col-form-label" for="message-text"
                          >Other details</label
                        >
                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.detail"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            :placeholder="formData.detail"
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.active ? `bg-info text-white` : ``"
                        ><span class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleRoot"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.is_root ? `bg-info text-white` : ``"
                      >
                        <span class="fas fa-user me-2"></span>Root/Parent?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleShowOnMain"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.show_on_main_page ? `bg-info text-white` : ``
                        "
                        ><span class="fas fa-align-left me-2"></span>Show on
                        Drawer</a
                      >
                    </li>
                    <br />
                    <hr />
                    <li class="nav-item me-2 me-lg-0 mt-auto">
                      <a
                        class="btn btn-primary nav-link nav-link-card-details"
                        role="button"
                        @click="saveResource"
                        ><span class="fa fa-save me-2"></span>Save
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import TenantService from "../services/tenant.service";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";

const now = new Date();

export default {
  mixins: [mixins],
  name: "TenantsView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        phone: { required },
        email: { required, email },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "mobile_no", "email"],
      headers: [
        { text: "#", value: "ID", sortable: true, widh: 10 },
        { text: "Name", value: "NAME", sortable: true },
        { text: "Phone", value: "MOBILE_NO", sortable: true },
        { text: "Email", value: "EMAIL", sortable: true },
        { text: "Registered at", value: "CREATED_AT", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getData();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      this.v$.formData.link.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      TenantService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.group("tenant");
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.$store.commit("SET_LOADING", true);
      this.formData.id = resource.id;
      if (!this.formData.icon) {
        this.formData.icon = resource.icon;
      }
      if (!this.formData.title) {
        this.formData.title = resource.title;
      }
      if (!this.formData.color) {
        this.formData.color = resource.color;
      }
      if (!this.formData.detail) {
        this.formData.detail = resource.detail;
      }
      if (!this.formData.order) {
        this.formData.order = resource.order;
      }
      if (!this.formData.link) {
        this.formData.link = resource.link;
      }
      if (!this.formData.id_parent) {
        this.formData.id_parent = resource.id_parent;
      }
      if (!this.formData.is_root) {
        this.formData.is_root = resource.is_root;
      }
      if (!this.formData.active) {
        this.formData.active = resource.active;
      }
      if (!this.formData.show_on_main_page) {
        this.formData.show_on_main_page = resource.show_on_main_page;
      }
      TenantService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            // Clear modals
            $("#edit-modal" + resource.id)
              .removeClass("fade")
              .hide();
            $(".modal-backdrop").remove();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        TenantService.delete(resource.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.id)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return TenantService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
