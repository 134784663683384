<template>
    <div>
        <div class="card mb-3">
            <div class="card-body">
                <div class="row justify-content-between align-items-center">
                    <div class="col-9">
                    </div>
                    <div class="col-3 d-flex text-right">
                        <download-excel :data="resources" name="tenant-analysis-report-bulla" class="ms-auto">
                            <button class="btn btn-falcon-primary btn-sm me-1  mb-2 mb-sm-0 d-flex" type="button">
                                <span class="fas fa-file-excel m-1"> </span>Excel
                            </button>
                        </download-excel>
                        <a class="btn btn-falcon-info btn-sm mb-2 mb-sm-0 d-flex" :href="pdfUrl" target="_blank">
                            <span class="fas fa-file-pdf m-1"> </span> Pdf
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-body">
                <div class="row align-items-center text-center mb-3">
                    <div class="col text-sm-center mt-2 mt-sm-0">
                        <h4 class="my-2">Tenants Analysis Report</h4>
                        <h6 class="mb-0">
                            {{ user.company.name }}, {{ user.company.address_line1 }}
                        </h6>
                        <p class="fs--1 mb-0">{{ user.company.address_line2 }}</p>
                        <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>
                        <p class="fs--1 mb-0">Date : {{ formData.from }} To : {{ formData.to }}</p>
                    </div>
                </div>
                <div class="table-responsive scrollbar mt-1 fs--1">
                    <table class="table table-striped border-bottom">
                        <thead class="light">
                            <tr class="bg-primary text-white dark__bg-1000">
                                <th class="border-0">Tenant Name</th>
                                <th class="border-0">Email Address</th>
                                <th class="border-0">Phone No</th>
                                <th class="border-0">KRA PIN</th>
                                <th class="border-0">ID No</th>
                                <th class="border-0">Gender</th>
                                <th class="border-0">Nationality</th>
                                <th class="border-0 text-end">Actual Rent</th>
                                <th class="border-0 text-end">Actual Deposit</th>
                            </tr>
                        </thead>
                        <tbody v-if="resources.length > 0">
                            <tr v-for="(item, i) in resources" :key="i">
                                <td class="mb-0 text-nowrap">
                                    {{ item.salutation }}. {{ item.surname }} {{ item.other_names }}
                                </td>
                                <td class="mb-0">{{ item.email }}</td>
                                <td class="mb-0">{{ item.phone }}</td>
                                <td class="mb-0">{{ item.kra_pin }}</td>
                                <td class="mb-0">{{ item.id_no }}</td>
                                <td class="mb-0">{{ item.gender }}</td>
                                <td class="mb-0">{{ item.nationality }}</td>
                                <td class="mb-0 text-end">
                                    {{ item.unit ? formatToCurrency(item.unit?.neg_price) : 0 }}
                                </td>
                                <td class="mb-0 text-end">
                                    {{ item.unit ? formatToCurrency(item.unit?.deposit) : 0 }}
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="mb-0 text-nowrap" colspan="5">No records found!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer bg-light">
                <p class="fs--1 mb-0"><strong>Report by: </strong>Bulla POS!</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import VueHtml2pdf from "vue3-html2pdf";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import PropertyService from "../services/propertys.service";

const now = new Date();

export default {
    name: "LeaseAnalysisReportView",
    components: {
        NavDrawer,
        AppBar,
        flatPickr,
        FooterComp,
        VueHtml2pdf,
    },
    created() {
        this.getUser();
        this.getAllData();
        this.getAllPropertys();
    },
    data() {
        return {
            json_data: [
                {
                    name: "Tony Peña",
                    city: "New York",
                    country: "United States",
                    birthdate: "1978-03-15",
                    phone: {
                        mobile: "1-541-754-3010",
                        landline: "(541) 754-3010",
                    },
                },
                {
                    name: "Thessaloniki",
                    city: "Athens",
                    country: "Greece",
                    birthdate: "1987-11-23",
                    phone: {
                        mobile: "+1 855 275 5071",
                        landline: "(2741) 2621-244",
                    },
                },
            ],
            summary: [],
            resources: [],
            propertys: [],
            preview_modal: false,
            enable_download: false,
            formData: {
                from: moment(new Date()).format("YYYY-MM-DD"),
                to: moment(new Date()).format("YYYY-MM-DD"),
                property: 0,
            },
            config: {
                dateFormat: "M j, Y",
            },
            user: {},
            config: {
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                locale: "en-us",
            },
        };
    },
    computed: {
        pdfUrl() {
            this.formData.company_id = this.user.company_id;
            let token = btoa(JSON.stringify(this.formData));
            return process.env.VUE_APP_BASE_URL + 'tenant-analysis-pdf/' + token;
        }
    },
    methods: {
        PropertySelected(e) {
            console.log(e.target.value);
            this.formData.property = e.target.value;
            this.getAllData();
        },
        getAllPropertys() {
            return PropertyService.index().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.propertys = response.data.data;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        formatDate(input) {
            return moment(input).format("DD-MM-YYYY HH:mm:ss");
        },

        getUser() {
            let user = localStorage.getItem("user");
            if (user) {
                this.user = JSON.parse(user);
            } else {
                this.handleLogout();
            }
        },
        handleLogout() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
        dateChanged() {
            console.log("Date changed..");
            console.log(this.formData);
            setTimeout(() => {
                this.getAllData();
            }, 500);
        },
        async getToday() {
            this.formData.from = await moment(new Date()).format("YYYY-MM-DD");
            this.formData.to = await moment(new Date()).format("YYYY-MM-DD");
            this.getAllData();
        },
        onProgress(e) {
            console.log(e);
        },
        formatToCurrency(amount) {
            if (amount || amount == 0) {
                const internationalNumberFormat = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "KES",
                });
                return internationalNumberFormat.format(amount);
            } else {
                return amount;
            }
        },
        getAllData() {
            this.$store.commit("SET_LOADING", true);
            return ReportService.tenantAnalysis(this.formData).then(
                (response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        this.resources = response.data.data;
                        this.loading = false;
                    } else {
                        this.loading = false;
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
    },
};
</script>
  