<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-sm-4 col-xs-4 align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart"></i>
              Stock Sheet
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Generate stock sheet reports
            </p>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <button
                class="btn btn-sm btn-falcon-warning ms-2 ms-auto"
                type="file"
                data-toggle="tooltip"
                data-placement="top"
                title="View report version"
                @click="$router.push('/stock-sheet/'+property)"
              >
                <i class="fas fa-file-pdf"></i> Report
              </button>
              <button
                class="btn btn-sm btn-falcon-warning ms-2"
                type="file"
                data-toggle="tooltip"
                data-placement="top"
                title="Export product"
                @click="exportData"
              >
                <i class="fas fa-file-excel"></i> Export
              </button>
              <button
                @click="forceReload"
                class="btn btn-sm btn-falcon-warning ms-2"
                data-toggle="tooltip"
                data-placement="top"
                title="Refresh page"
              >
                <i class="fa-solid fa-arrows-rotate"></i> Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <div
          id="tableExample3"
          data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
        >
          <div class="row justify-content-start g-0">
            <div class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3">
              <select
                class="form-select"
                id="recipient-name"
                type="text"
                @change="selectProperty"
                style="width: auto"
              >
                <option
                  v-for="(item, i) in propertys"
                  :key="i"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div
              class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3 d-flex ms-auto"
            >
              <div class="input-group ms-auto me-2">
                <input
                  class="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search..."
                  aria-label="search"
                  v-model="search_term"
                  v-on:keyup="searchItem"
                />
                <div class="input-group-text bg-transparent">
                  <span class="fa fa-search fs--1 text-600"></span>
                </div>
              </div>
              <button class="btn btn-falcon-warning mx-1" @click="goToPOS">
                POS
              </button>
            </div>
          </div>
          <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0">
              <thead class="bg-200 text-900">
                <tr>
                  <th class="sort" width="5%" >#</th>
                  <th class="sort" width="40%" >Name</th>
                  <th class="sort" width="15%" >Group</th>
                  <th class="sort" width="10%" >Stock</th>
                  <th class="sort" width="10%" >New Stock</th>
                  <th class="sort" width="10%">Units</th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="(item, i) in resources" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    <div class="my-2">
                      <strong>{{ item.name.toUpperCase() }}</strong>
                      <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
                    </div>
                  </td>
                  <td>{{ item.group ? item.group.name : "" }}</td>
                  <td>{{ item.stock_amount }}</td>
                  <td>
                    <input type="number" :id="item.id" class="form-control"/>
                  </td>
                  <td>{{ item.unit_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page"
              :records="records"
              :per-page="per_page"
              @paginate="myCallback"
            />
          </div>
        </div>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="edit-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit Products</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select atleast one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Select Group</label
                        >
                        <select
                          class="form-select"
                          id="recipient-name"
                          type="text"
                          v-model="formData.group_id"
                          @change="groupSelected"
                          :class="
                            v$.formData.group_id.$dirty &&
                            v$.formData.group_id.$invalid
                              ? `is-invalid`
                              : v$.formData.group_id.$dirty
                              ? `is-valid`
                              : ``
                          "
                        >
                          <option value="">Group...</option>
                          <option
                            v-for="(item, i) in groups"
                            :key="i"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <div
                          v-for="error of v$.formData.group_id.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Buying Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.buy_price"
                          :class="
                            v$.formData.buy_price.$dirty &&
                            v$.formData.buy_price.$invalid
                              ? `is-invalid`
                              : v$.formData.buy_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.buy_price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Selling Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.price"
                          :class="
                            v$.formData.price.$dirty &&
                            v$.formData.price.$invalid
                              ? `is-invalid`
                              : v$.formData.price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Stock Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.ge 10"
                          v-model="formData.stock_amount"
                          :class="
                            v$.formData.stock_amount.$dirty &&
                            v$.formData.stock_amount.$invalid
                              ? `is-invalid`
                              : v$.formData.stock_amount.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.stock_amount.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Photo</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                          @change="onFileChange"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >Reorder Level</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.reorder_level"
                          :class="
                            v$.formData.reorder_level.$dirty &&
                            v$.formData.reorder_level.$invalid
                              ? `is-invalid`
                              : v$.formData.reorder_level.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.reorder_level.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >WS Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.ws_price"
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="col-form-label" for="message-text"
                          >Barcode</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.barcode"
                        />
                      </div>
                      <label class="col-form-label" for="message-text"
                        >Description</label
                      >
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.description"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>

                      <div
                        v-if="formData.barcode && formData.barcode.length > 0"
                        class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      >
                        <div class="border border-success text-center">
                          <label
                            class="col-form-label mb-0 text-decoration-underline"
                            for="recipient-name"
                            >{{
                              formData.name ? formData.name.toUpperCase() : ""
                            }}</label
                          >
                          <br />
                          <p>{{ formData.description }}</p>
                          <div>
                            <vue-barcode
                              :value="formData.barcode"
                              :height="50"
                            />
                          </div>
                          <span>{{ formatToCurrency(formData.price) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Other Actions</div>
                </div>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.visible ? `bg-info text-white` : ``"
                      ><span class="fa fa-check me-2"></span>Active?
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <label class="col-form-label" for="message-text"
                      >Size</label
                    >
                    <select
                      class="form-select nav-link nav-link-card-details"
                      id="recipient-name"
                      type="text"
                      v-model="formData.unit_name"
                    >
                      <option value="">Sizes...</option>
                      <option
                        v-for="(item, i) in property_unit_sizes"
                        :key="i"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </li>
                  <br />
                  <hr />

                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Select Locations</div>
                  </div>
                  <div
                    class="border"
                    :class="
                      show_location_error ? 'border-danger' : 'border-success'
                    "
                  >
                    <li
                      v-for="(item, i) in propertys"
                      :key="i"
                      class="nav-item me-2 me-lg-0 my-2"
                    >
                      <a
                        @click="addToItems(item.id)"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.propertys &&
                          formData.propertys.includes(item.id)
                            ? `bg-info text-white`
                            : ``
                        "
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                    </li>
                  </div>

                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Actions</div>
                  </div>
                  <button class="d-flex ms-uto btn btn-falcon-primary">
                    Print Barcode
                  </button>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="updateResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      :aria-labelledby="edit - modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Products</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select atleast one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g Pilau"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Select Group</label
                        >
                        <select
                          class="form-select"
                          id="recipient-name"
                          type="text"
                          v-model="formData.group_id"
                          @change="groupSelected"
                          :class="
                            v$.formData.group_id.$dirty &&
                            v$.formData.group_id.$invalid
                              ? `is-invalid`
                              : v$.formData.group_id.$dirty
                              ? `is-valid`
                              : ``
                          "
                        >
                          <option value="">Group...</option>
                          <option
                            v-for="(item, i) in groups"
                            :key="i"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <div
                          v-for="error of v$.formData.group_id.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Buying Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          placeholder="e.g 5"
                          v-model="formData.buy_price"
                          :class="
                            v$.formData.buy_price.$dirty &&
                            v$.formData.buy_price.$invalid
                              ? `is-invalid`
                              : v$.formData.buy_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.buy_price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Selling Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          placeholder="e.g 5"
                          v-model="formData.price"
                          :class="
                            v$.formData.price.$dirty &&
                            v$.formData.price.$invalid
                              ? `is-invalid`
                              : v$.formData.price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Stock Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          placeholder="e.g 10"
                          v-model="formData.stock_amount"
                          :class="
                            v$.formData.stock_amount.$dirty &&
                            v$.formData.stock_amount.$invalid
                              ? `is-invalid`
                              : v$.formData.stock_amount.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.stock_amount.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Photo</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                          @change="onFileChange"
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >Reorder Level</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.reorder_level"
                          :class="
                            v$.formData.reorder_level.$dirty &&
                            v$.formData.reorder_level.$invalid
                              ? `is-invalid`
                              : v$.formData.reorder_level.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.reorder_level.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >WS Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.ws_price"
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="col-form-label" for="message-text"
                          >Barcode</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.barcode"
                        />
                      </div>
                      <label class="col-form-label" for="message-text"
                        >Description</label
                      >
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.description"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Other Actions</div>
                </div>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.visible ? `bg-info text-white` : ``"
                      ><span class="fa fa-check me-2"></span>Active?
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <label class="col-form-label" for="message-text"
                      >Size</label
                    >
                    <select
                      class="form-select nav-link nav-link-card-details"
                      id="recipient-name"
                      type="text"
                      v-model="formData.unit_name"
                    >
                      <option value="">Unit...</option>
                      <option
                        v-for="(item, i) in property_unit_sizes"
                        :key="i"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </li>

                  <br />
                  <hr />
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Select Locations</div>
                  </div>
                  <div
                    class="border"
                    :class="
                      show_location_error ? 'border-danger' : 'border-success'
                    "
                  >
                    <li
                      v-for="(item, i) in propertys"
                      :key="i"
                      class="nav-item me-2 me-lg-0 my-2"
                    >
                      <a
                        @click="addToItems(item.id)"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.propertys &&
                          formData.propertys.includes(item.id)
                            ? `bg-info text-white`
                            : ``
                        "
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="import-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="import-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Products</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div
                  v-if="show_location_error"
                  class="alert alert-danger"
                  role="alert"
                >
                  You must select atleast one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input
                          type="file"
                          id="import-excel"
                          @change="importExcel()"
                        />
                      </div>
                    </div>
                    <hr class="my-2" />
                    <div class="table-responsive">
                      <table class="table">
                        <caption>
                          List of items
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Buying Price</th>
                            <th scope="col">Selling Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Units</th>
                            <th scope="col">Category</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in import_resources" :key="i">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ item[0] }}</td>
                            <td>{{ item[1] }}</td>
                            <td>{{ item[2] }}</td>
                            <td>{{ item[3] }}</td>
                            <td>{{ item[4] }}</td>
                            <td>{{ item[5] }}</td>
                            <td>{{ item[6] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-warning ms-2 rounded-pill h1 d-block"
              type="button"
              @click="uploadData"
            >
              <span class="d-none d-sm-inline-block ms-1">
                <i class="fa fa-cloud"></i> Upload</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End import modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import Pagination from "v-pagination-3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import VueBarcode from "@chenfengyuan/vue-barcode";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import PropertyUnitsService from "../services/property_unit.service";
import MenuGroupService from "../services/menu_group.service";
import PropertyUnitSizeService from "../services/property_unit_size.service";
import PropertyService from "../services/propertys.service";

import readXlsFile from "read-excel-file";
import exportXlsFile from "export-from-json";

const now = new Date();

export default {
  mixins: [mixins],
  name: "TenantsView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        price: { required },
        group_id: { required },
        buy_price: { required },
        stock_amount: { required },
        reorder_level: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    Pagination,
    VueBarcode,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      per_page: 18,
      current_page: 0,
      records: 0,
      search_term: "",
      file: "",
      property: "",
      filename: "",
      selected: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      propertys: [],
      selected_items: "",
      import_resources: [],
      property_unit_sizes: [],
      productCategories: [],
      selected_category: "",
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        barcode: "",
        unit_name: "",
        unit_name: "",
        description: "",
        propertys: [],
        barcode: "",
        reorder_level: 0,
      },
      resources: [],
      orders_table_key: 0,
      show_location_error: false,
      searchField: ["ID", "NAME", "PRICE"],
      headers: [
        { text: "#", value: "id", sortable: true, width: 10 },
        { text: "Photo", value: "image", sortable: true, width: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Group", value: "group_id", sortable: true },
        { text: "B.Price", value: "buy_price", sortable: true },
        { text: "S.Price", value: "price", sortable: true },
        { text: "Details", value: "description", sortable: true },
        { text: "Barcode", value: "barcode", sortable: true },
        { text: "Stock Amount", value: "stock_amount", sortable: true },
        { text: "Units", value: "unit_name", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.getAllPropertys();
    await this.getGroups();
    await this.getPropertyUnitSize();
    this.$store.commit("SET_LOADING", false);
    this.getPropertyUnits(1);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  methods: {
    myCallback(page) {
      this.page = page;
      this.getPropertyUnits(page);
    },
    async uploadData() {
      this.$store.commit("SET_LOADING", true);
      console.log(this.import_resources);
      let formatData = await this.import_resources.map((element) => {
        return {
          name: element[0],
          description: element[1],
          buy_price: element[2],
          price: element[3],
          stock_amount: element[4],
          unit_name: element[5],
        };
      });
      console.log(formatData);
      let json_data = JSON.stringify(formatData);
      await PropertyUnitsService.import({ excel_data: json_data }).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data);
            this.page = 1;
            this.getPropertyUnits(1);
            return response.data.data;
          } else {
            return [];
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          return [];
        }
      );
      toast.success("Items Uploaoded successfuly!");
      this.$store.commit("SET_LOADING", false);
    },
    importExcel() {
      const input = document.getElementById("import-excel");
      readXlsFile(input.files[0]).then((rows) => {
        this.import_resources = rows.filter((item, index) => index != 0);
      });
    },
    async exportData() {
      this.$store.commit("SET_LOADING", true);
      const data = await PropertyUnitsService.all(this.property).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data);
            return response.data.data;
          } else {
            return [];
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          return [];
        }
      );
      const fileName = "BullaPOs Expoorted Products";
      const exportType = exportXlsFile.types.xls; // xls
      exportXlsFile({ data, fileName, exportType });
      this.$store.commit("SET_LOADING", false);
    },
    searchItem() {
      this.page = 1;
      this.current_page = 1;
      let formData = {
        search_term: this.search_term,
      };
      this.$store.commit("SET_LOADING", true);
      return PropertyUnitsService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    goToBarcode() {
      this.$router.push("/barcode");
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    addNew() {
      this.formData = {
        group_id: "",
        active: 0,
        visible: 1,
        barcode: "",
        unit_name: "",
        description: "",
        propertys: [],
      };
      this.file = "";
      this.filename = "";
      this.show_location_error = false;
    },
    editItem(item) {
      console.log(item);
      this.show_location_error = false;
      this.formData = item;
      this.formData.propertys = [];
    },
    addToItems(type_id) {
      if (
        this.formData.propertys &&
        this.formData.propertys.includes(type_id)
      ) {
        this.formData.propertys = this.formData.propertys.filter(
          (el) => el != type_id
        );
      } else {
        this.formData.propertys.push(type_id);
      }
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.filename = "Selected File: " + e.target.files[0].name;
      this.file = e.target.files[0];
    },

    async selectProperty(e) {
      this.property = e.target.value;
      await this.getPropertyUnits(this.page);
      this.$store.commit("SET_LOADING", false);
    },
    getAllPropertys() {
      return PropertyService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.propertys = response.data.data;
            this.property = response.data.data.at(0).id;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    sortItems(list) {
      return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItems() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getPropertyUnits(page) {
      this.$store.commit("SET_LOADING", true);
      return PropertyUnitsService.byOrder(page, this.property).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    groupSelected(e) {
      this.formData.GROUP_ID = e.target.value;
    },
    getPropertyUnitSize() {
      return PropertyUnitSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.property_unit_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      console.log(this.formData);
      this.formData.VISIBLE = !this.formData.VISIBLE;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.price.$touch();
      this.v$.formData.group_id.$touch();
      this.v$.formData.buy_price.$touch();
      this.v$.formData.stock_amount.$touch();
      this.v$.formData.reorder_level.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      if (this.formData.propertys.length == 0) {
        this.show_location_error = true;
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("propertys", this.formData.propertys);
      formData.append("name", this.formData.name);
      formData.append("reorder_level", this.formData.reorder_level);
      formData.append("price", this.formData.price);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      PropertyUnitsService.store(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            this.page = 1;
            this.current_page = 1;
            this.getPropertyUnits(this.page);
            toast.success(response.data.message, {
              autoClose: 2000,
            });
          } else {
            console.log(response);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.price.$touch();
      this.v$.formData.group_id.$touch();
      this.v$.formData.buy_price.$touch();
      this.v$.formData.stock_amount.$touch();
      this.v$.formData.reorder_level.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      if (this.formData.propertys.length == 0) {
        this.show_location_error = true;
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("propertys", this.formData.propertys);
      formData.append("id", this.formData.id);
      formData.append("name", this.formData.name);
      formData.append("reorder_level", this.formData.reorder_level);
      formData.append("price", this.formData.price);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      PropertyUnitsService.update2(formData, config).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getPropertyUnits();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteResource(resource) {
      console.log(resource);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        PropertyUnitsService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
              this.getPropertyUnits();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return PropertyUnitsService.index().then(
        (response) => {
          console.log("getdata");
          console.group(response);
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
