<template>
  <div>
    <div class="row mb-2">
      <div class="">
        <div class="card bg-100 shadow-none border">
          <div class="row gx-0 flex-between-center">
            <div class="col-sm-auto d-flex align-items-center">
              <img
                class="ms-n2"
                src="assets/img/illustrations/crm-bar-chart.png"
                alt=""
                width="90"
              />
              <div>
                <h6 class="text-primary fs--1 mb-0">Manage All</h6>
                <h4 class="text-warning fw-bold mb-0">Menu Items</h4>
              </div>
              <img
                class="ms-n4 d-md-none d-lg-block"
                src="assets/img/illustrations/crm-line-chart.png"
                alt=""
                width="150"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <div class="card theme-wizard mb-2">
        <div class="border-bottom pt-2">
          <ul class="nav justify-content-between nav-wizard">
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">1</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Create Locations</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">2</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Categories</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">3</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Groups</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link fw-semi-bold"
                ><span class="nav-item-circle-parent"
                  ><span class="nav-item-circle">3</span></span
                ><span class="d-none d-md-block mt-1 fs--1">Menu Items</span></a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div
              class="row g-3 d-flex tab-pane active"
              role="tabpanel"
              aria-labelledby="bootstrap-wizard-tab1"
              id="bootstrap-wizard-tab1"
            >
              <div class="col-3 text-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_flagged_2uty.svg"
                  alt="Product Image"
                  width="130"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_confirmation_re_b6q5.svg"
                  alt="Product Image"
                  width="130"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_shopping_app_flsj.svg"
                  alt="Product Image"
                  width="130"
                />
              </div>
              <div class="col-3 d-flex justify-content-center">
                <img
                  class="img-fluid rounded-top"
                  src="assets/img/illustrations/undraw_product_iteration_kjok.svg"
                  alt="Product Image"
                  width="130"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <div class="col">
        <div class="card bg-100 shadow-none border">
          <div class="row gx-0 flex-between-center p-2">
            <div
              class="col-md-auto d-flex my-0"
              v-for="(item, i) in propertys"
              :key="i"
            >
              <button
                @click="selectProperty(item)"
                class="py-3 border text-center btn"
                role="button"
                :class="item.id == property.id ? ` btn-primary` : ` btn-falcon-primary`"
              >
                {{ item.name }}
              </button>
            </div>
            <div class="col-md-auto">
              <a
                class="nav-link nav-link-card-details py-3 mx-2 border border-warning text-warning text-center px-4"
                role="button"
                width="100"
                @click="toggleLocation(true)"
              >
                <span v-show="!add_location">
                  <i class="fa fa-plus"></i> New Location
                </span>
                <input
                  ref="add_location_key"
                  v-show="add_location"
                  type="text"
                  @change="addLocations"
                  @blur="toggleLocation(false)"
                  placeholder="Location Name"
                  v-model="formData.location_name"
                  class="form-control"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mb-2 me-2">
      <div
        class="card col-12 col-md-2 col-sm-6 col-xs-6 scrollbar list py-3 px-1 mb-3 me-1"
        style="max-height: 100%"
      >
        <h6
          class="border-bottom mb-1 dropdown-header mt-lg-0 text-uppercase px-card fs--4 pt-0 pb-2"
        >
          Groups
        </h6>
        <ul class="nav flex-lg-column fs--1">
          <li class="nav-item me-2 me-lg-0 d-block">
            <a
              class="nav-link nav-link-card-details py-3 mx-2 border border-warning text-warning text-center"
              role="button"
              @click="toggleGroup(true)"
            >
              <span v-show="!add_group"> <i class="fa fa-plus"></i> New Group </span>
              <input
                ref="add_group_key"
                v-show="add_group"
                type="text"
                @change="addGroup"
                @blur="toggleGroup(false)"
                placeholder="Name..."
                v-model="formData.group_name"
                class="form-control"
              />
            </a>
          </li>
          <li
            v-for="(item, i) in groups"
            class="nav-item me-2 me-lg-0 d-block"
            @click="getPropertyUnitsByGroup(item.id)"
          >
            <a
              class="nav-link nav-link-card-details py-3 mx-2 border"
              role="button"
              :class="selected_group == item.id ? `bg-primary text-white` : ``"
              >{{ item.name }}
            </a>
          </li>
        </ul>
      </div>
      <!-- Start goups  -->
      <div class="col-12 col-lg-8 col-md-8 col-sm-4 col-xs-4 me-1">
        <div class="card mb-2">
          <div class="card-body">
            <div class="row flex-between-center">
              <div class="col-sm-auto mb-2 mb-sm-0">
                <h6 class="mb-0">Showing {{ items.length }} Products</h6>
              </div>
            </div>
          </div>
        </div>
        <!-- Edn goups  -->
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-12 col-mlg-12 col-xl-12">
                <div class="row">
                  <div
                    class="col-4 col-md-2 col-lg-2 border rounded-1 d-flex flex-column justify-content-between border-warning"
                    data-bs-toggle="modal"
                    data-bs-target="#product-add-modal"
                  >
                    <div class="overflow-hidden">
                      <div class="position-relative rounded-top overflow-hidden">
                        <a class="d-block text-center" role="button">
                          <i class="fa fa-plus text-warning h3 mt-4"></i>
                        </a>
                      </div>
                      <div class="p-1 text-center">
                        <h5 class="fs--1">
                          <a class="text-dark" role="button"> Add </a>
                        </h5>
                        <span class="fs--2 fw-bold text-warning d-block" role="button">
                          New
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="items.length > 0"
                    class="col-4 col-md-2 col-lg-2 border rounded-1 d-flex flex-column justify-content-between"
                    v-for="(item, i) in items"
                    :key="i"
                  >
                    <div class="overflow-hidden">
                      <div class="position-relative rounded-top overflow-hidden">
                        <a class="d-block text-center" role="button"
                          ><img
                            class="img-fluid rounded-top"
                            src="assets/img/no-image.jpg"
                            alt="Product Image"
                            width="100"
                        /></a>
                      </div>
                      <div class="p-1 text-center">
                        <h5 class="fs--1">
                          <a class="text-dark" role="button">
                            {{ truncateText(item.name, 15) }}
                          </a>
                        </h5>
                        <span class="fs--3 fw-bold text-warning d-block" role="button">
                          {{ formatToCurrency(item.price) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p v-else>No item found!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card col-11 col-md-2 col-sm-5 col-xs-5 scrollbar list py-3 mb-3 pe-n5 me-5"
        style="max-height: 100%"
      >
        <h6
          class="border-bottom mb-1 dropdown-header mt-lg-0 text-uppercase px-card fs--4 pt-0 pb-2"
        >
          Categories
        </h6>
        <ul class="nav flex-lg-column fs--1">
          <li class="nav-item me-2 me-lg-0 d-block">
            <a
              class="nav-link nav-link-card-details py-3 mx-2 border border-warning text-warning text-center"
              role="button"
              @click="toggleCategory(true)"
            >
              <span v-show="!add_category">
                <i class="fa fa-plus"></i> New Category
              </span>
              <input
                ref="add_category_key"
                v-show="add_category"
                type="text"
                @change="addCategory"
                @blur="toggleCategory(false)"
                placeholder="Category name.."
                v-model="formData.category_name"
                class="form-control"
              />
            </a>
          </li>
          <li
            v-for="(item, i) in productCategories"
            :key="i"
            @click="getGroups(item)"
            class="nav-item me-2 me-lg-0 d-block"
          >
            <a
              class="nav-link nav-link-card-details py-3 mx-2 border"
              role="button"
              :class="selected_category == item.id ? `bg-success text-white` : ``"
              >{{ item.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- Start add product modal  -->
    <div
      class="modal fade"
      id="product-add-modalold"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">New product</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div v-if="show_location_error" class="alert alert-danger" role="alert">
                  You must select atleast one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name">Name</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >Buying Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.buy_price"
                          :class="
                            v$.formData.buy_price.$dirty && v$.formData.buy_price.$invalid
                              ? `is-invalid`
                              : v$.formData.buy_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.buy_price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >Selling Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.price"
                          :class="
                            v$.formData.price.$dirty && v$.formData.price.$invalid
                              ? `is-invalid`
                              : v$.formData.price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="recipient-name">Photo</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                          @change="onFileChange"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >Stock Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.stock_amount"
                          :class="
                            v$.formData.stock_amount.$dirty &&
                            v$.formData.stock_amount.$invalid
                              ? `is-invalid`
                              : v$.formData.stock_amount.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.stock_amount.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text">Barcode</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.barcode"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <label class="col-form-label" for="message-text"
                          >WholeSale Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.ws_price"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <label class="col-form-label" for="message-text">Description</label>
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.description"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-warning ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add product  -->

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="product-add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="product-add-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add Products</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div v-if="show_location_error" class="alert alert-danger" role="alert">
                  You must select at least one location!
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row mt-2">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name">Name</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g Pilau"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Select Group</label
                        >
                        <select
                          class="form-select"
                          id="recipient-name"
                          type="text"
                          v-model="formData.group_id"
                          @change="groupSelected"
                          :class="
                            v$.formData.group_id.$dirty && v$.formData.group_id.$invalid
                              ? `is-invalid`
                              : v$.formData.group_id.$dirty
                              ? `is-valid`
                              : ``
                          "
                        >
                          <option value="">Group...</option>
                          <option v-for="(item, i) in groups" :key="i" :value="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                        <div
                          v-for="error of v$.formData.group_id.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Buying Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          placeholder="e.g 5"
                          v-model="formData.buy_price"
                          :class="
                            v$.formData.buy_price.$dirty && v$.formData.buy_price.$invalid
                              ? `is-invalid`
                              : v$.formData.buy_price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.buy_price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Selling Price</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          placeholder="e.g 5"
                          v-model="formData.price"
                          :class="
                            v$.formData.price.$dirty && v$.formData.price.$invalid
                              ? `is-invalid`
                              : v$.formData.price.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.price.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text">WS Price</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.ws_price"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <label class="col-form-label" for="message-text"
                          >Reorder Level</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          v-model="formData.reorder_level"
                          :class="
                            v$.formData.reorder_level.$dirty &&
                            v$.formData.reorder_level.$invalid
                              ? `is-invalid`
                              : v$.formData.reorder_level.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.reorder_level.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name">Photo</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                          @change="onFileChange"
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Stock Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          disabled
                          placeholder="e.g 10"
                          v-model="formData.stock_amount"
                        />
                      </div>
                      <!-- ORDER TYPES QUANTITIES  -->
                      <div
                        v-for="(item, i) in selected_propertys"
                        :key="i"
                        class="mb-3 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                      >
                        <label class="col-form-label" for="message-text"
                          >{{ item.name.toUpperCase() }} Quantity</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          placeholder="e.g 5"
                          @change="addLocationQty($event, item)"
                        />
                      </div>

                      <div class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label class="col-form-label" for="message-text">Barcode</label>
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.barcode"
                        />
                      </div>
                      <label class="col-form-label" for="message-text">Description</label>
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.description"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <div class="position-relative">
                  <hr />
                  <div class="divider-content-center">Other Actions</div>
                </div>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.visible ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>Active?
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleSellable"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.sellable ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>Sellable?
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <a
                      @click="toggleVAT"
                      class="nav-link nav-link-card-details"
                      role="button"
                      :class="formData.vat ? `bg-warning text-white` : ``"
                      ><span class="fa fa-question me-2"></span>VAT(16%)
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0 my-2">
                    <label class="col-form-label" for="message-text">Size</label>
                    <select
                      class="form-select nav-link nav-link-card-details"
                      id="recipient-name"
                      type="text"
                      v-model="formData.unit_name"
                    >
                      <option value="">Unit...</option>
                      <option
                        v-for="(item, i) in property_unit_sizes"
                        :key="i"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </li>
                  <hr />
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Select Locations</div>
                  </div>
                  <div
                    class="border"
                    :class="show_location_error ? 'border-danger' : 'border-success'"
                  >
                    <li
                      v-for="(item, i) in propertys"
                      :key="i"
                      class="nav-item me-2 me-lg-0 my-2"
                    >
                      <a
                        @click="addToLocations(item)"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="
                          formData.propertys && formData.propertys.includes(item.id)
                            ? `bg-info text-white`
                            : ``
                        "
                        ><span class="fa fa-check me-2"></span>
                        {{ item.name }}
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import CartService from "../services/cart.service";
import TablesService from "../services/tables.service";
import LeaseService from "../services/lease.service";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import TenantService from "../services/tenant.service";
import PropertyUnitsService from "../services/property_unit.service";
import MenuGroupService from "../services/menu_group.service";
import PropertyService from "../services/propertys.service";
import PropertyTypeService from "../services/property_type.service";
import PropertyUnitSizeService from "../services/property_unit_size.service";

export default {
  mixins: [mixins],
  validations() {
    return {
      formData: {
        name: { required },
        price: { required },
        group_id: { required },
        buy_price: { required },
        stock_amount: { required },
        reorder_level: { required },
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
  },
  data() {
    return {
      location_quantities: [],
      selected_propertys: [],
      property_unit_sizes: [],
      add_group: false,
      add_category: false,
      add_location: false,
      checkout: false,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        unit_name: "",
        propertys: [],
      },
      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      items: {},
      tables: [],
      propertys: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      tenants: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      property_selection: true,
      property:
        localStorage.getItem("property") == null
          ? {}
          : JSON.parse(localStorage.getItem("property")),
      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_tenant: "",
    };
  },
  watch: {
    selected_group(newVal, oldVal) {
      this.formData.GROUP_ID = newVal;
    },
  },
  async created() {
    // this.forceReload();
    this.$store.commit("SET_LOADING", true);
    await this.getAllPropertys();
    this.property.id = this.propertys.at(0).id;
    await this.getPropertyTypeByOrder();
    this.selected_category = this.productCategories.at(0).id;
    await this.getMenuGroupsByCategory();
    await this.getPropertyUnitsByGroup(this.groups.at(0).id);
    await this.getAllTables();
    this.selected_table = this.tables.at(0);
    this.getTenants();
    this.getPropertyUnitSize();
    this.$store.commit("SET_LOADING", false);
  },
  computed: {
    totalValue() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue =
          totalValue + parseFloat(element.quantity) * parseFloat(element.price);
      });
      return parseFloat(totalValue);
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      let cartData = Object.values(this.cart);
      return cartData;
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    addLocationQty(e, location) {
      let data = {
        quantity: parseInt(e.target.value),
        location_id: location.id,
      };
      // exist
      let exist = this.location_quantities.find((el) => el.location_id == location.id);
      if (exist) {
        this.location_quantities.map((el) => {
          if (el.location_id == location.id) {
            el.quantity = parseInt(e.target.value);
          }
        });
      } else {
        this.location_quantities.push(data);
      }
      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    addToLocations(location) {
      if (this.formData.propertys && this.formData.propertys.includes(location.id)) {
        this.formData.propertys = this.formData.propertys.filter(
          (el) => el != location.id
        );
        this.selected_propertys = this.selected_propertys.filter(
          (el) => el.id != location.id
        );
        this.location_quantities = this.location_quantities.filter(
          (el) => el.location_id != location.id
        );
      } else {
        this.formData.propertys.push(location.id);
        this.selected_propertys.push(location);
      }

      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    toggleActive() {
      this.formData.visible = !this.formData.visible;
    },
    toggleVAT() {
      this.formData.vat = !this.formData.vat;
    },
    toggleSellable() {
      this.formData.sellable = !this.formData.sellable;
    },

    addToLocations(location) {
      if (this.formData.propertys && this.formData.propertys.includes(location.id)) {
        this.formData.propertys = this.formData.propertys.filter(
          (el) => el != location.id
        );
        this.selected_propertys = this.selected_propertys.filter(
          (el) => el.id != location.id
        );
        this.location_quantities = this.location_quantities.filter(
          (el) => el.location_id != location.id
        );
      } else {
        this.formData.propertys.push(location.id);
        this.selected_propertys.push(location);
      }

      this.formData.stock_amount = this.location_quantities.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
    },
    addToItems(type_id) {
      if (this.formData.propertys && this.formData.propertys.includes(type_id)) {
        this.formData.propertys = this.formData.propertys.filter(
          (el) => el != type_id
        );
      } else {
        this.formData.propertys
          ? this.formData.propertys.push(type_id)
          : (this.formData.propertys = [type_id]);
      }
    },
    saveResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.price.$touch();
      this.v$.formData.group_id.$touch();
      this.v$.formData.buy_price.$touch();
      this.v$.formData.stock_amount.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      if (this.file) {
        formData.append("file", this.file);
      }
      let arr = [];
      arr.push(this.property.id);
      formData.append("propertys", arr);
      formData.append("group_id", this.selected_group);
      formData.append("name", this.formData.name);
      formData.append("price", this.formData.price);
      formData.append("ws_price", this.formData.ws_price);
      formData.append("barcode", this.formData.barcode);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("description", this.formData.description);
      formData.append("stock_amount", this.formData.stock_amount);
      formData.append("vat", this.formData.vat ? 1 : 0);
      formData.append("sellable", this.formData.sellable ? 1 : 0);
      formData.append("visible", this.formData.visible ? 1 : 0);
      formData.append("location_quantities", JSON.stringify(this.location_quantities));

      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      PropertyUnitsService.store(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getPropertyUnitsByGroup(this.selected_group);
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getPropertyUnitSize() {
      return PropertyUnitSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.property_unit_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleCategory(val) {
      this.add_category = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_category_key.focus();
        }, 400);
      }
    },
    addCategory() {
      this.add_category = false;
      let data = {
        name: this.formData.category_name,
        property_id: this.property.id,
      };
      PropertyTypeService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Category created!");
            this.formData = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.getPropertyTypeByOrder();
          this.$store.commit("SET_LOADING", true);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          toast.error("The name already exist!");
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    toggleGroup(val) {
      this.add_group = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_group_key.focus();
        }, 400);
      }
    },
    toggleLocation(val) {
      this.add_location = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_location_key.focus();
        }, 400);
      }
    },
    addGroup() {
      if (this.selected_category == "") {
        toast.info("Please create/select categories first and select one!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.add_group = false;
      let data = {
        name: this.formData.group_name,
        property_id: this.property.id,
        category_id: this.selected_category,
      };
      MenuGroupService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Group created!");
            this.formData = {};
            // get groups
            this.getMenuGroupsByCategory();
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.getMenuGroupsByOrder();
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
          toast.error("The name already exist!");
        }
      );
    },
    addLocations() {
      this.$store.commit("SET_LOADING", true);
      this.add_location = false;
      let data = {
        name: this.formData.location_name,
      };
      PropertyService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Location created!");
            this.formData = {};
            this.getAllPropertys();
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
          toast.error("The name already exist!");
        }
      );
    },
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.id == item.id);
        existingElement.quantity = quantity;
        existingElement.sub_total = quantity * item.price;
      }
      this.cart = myCart;
      toast.success("1 " + item.NAME + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      // this.formData.balance_amount =  this.formData.tender_amount;
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.property_id = this.property.id;
      this.formData.property_name = this.property.name;
      LeaseService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.property_id = this.property.id;
      this.formData.property_name = this.property.name;
      LeaseService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    getGroups(cat) {
      console.log(cat);
      this.selected_category_data = cat;
      this.selected_category = cat.id;
      if (this.selected_category == null) {
        this.getItemsByOrder(this.property);
      } else {
        this.getMenuGroupsByCategory();
      }
      // this.getPropertyUnitsByGroup(this.groups[0].id);
    },
    getMenuGroupsByCategory() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        property_type: this.selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            console.log(response.data.data);
            this.selected_group = response.data.data.at(0)
              ? response.data.data.at(0).id
              : "";
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    filterItems() {
      this.searchKey = this.searchKey.toLowerCase();
      if (this.searchKey) {
        let array = Object.values(this.items);
        this.items = array.filter((item) => {
          let name = item.name.toLowerCase();
          return name.includes(this.searchKey);
        });
      } else {
        this.getAll();
      }
    },
    orderLease() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.id;
      this.formData.total_amount = this.totalValue;
      this.formData.property_id = this.property.id;
      this.formData.property_name = this.property.name;
      LeaseService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    getItemsByOrder(item) {
      return CartService.byOrder(item.id).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getPropertyUnitsByGroup(id) {
      this.$store.commit("SET_LOADING", true);
      this.selected_group = id;
      let formData = {
        menu_group_id: this.selected_group,
      };
      return ProductService.getPropertyUnitsByGroup(formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllTables() {
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllPropertys() {
      return PropertyService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.propertys = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        property: this.property.id,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getPropertyTypeByOrder() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        property: this.property.id,
      };
      return ProductService.getPropertyTypeByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getTenants() {
      // samdoh
      return TenantService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tenants = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllGroups() {
      return ProductService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    async selectProperty(item) {
      this.property = item;
      this.table_selection = true;
      this.property_selection = false;
      localStorage.setItem("property", JSON.stringify(item));
      await this.getItemsByOrder(item.id);
      await this.getPropertyTypeByOrder();
      this.selected_category = this.productCategories.at(0).id;
      if (this.productCategories.length > 0) {
        await this.getMenuGroupsByCategory(this.productCategories.at(0).id);
        if (this.groups.at(0)) {
          await this.getPropertyUnitsByGroup(this.groups.at(0).id);
        } else {
          this.items = [];
        }
      } else {
        this.groups = [];
        this.selected_group = "";
        this.items = [];
      }
    },
  },
};
</script>
