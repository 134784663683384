// Data sevice
import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class PropertyUnitsService {
  all(property) {
    return axios.get(VUE_APP_BASE_URL + "property-unit-all/" + property, {
      headers: authHeader(),
    });
  }
  index() {
    return axios.get(VUE_APP_BASE_URL + "property-unit", {
      headers: authHeader(),
    });
  }
  getArchived() {
    return axios.get(VUE_APP_BASE_URL + "property-unit-archived", {
      headers: authHeader(),
    });
  }
  byOrder(page, property) {
    return axios.get(
      VUE_APP_BASE_URL + "property-unit-byorder/" + property + "?page=" + page,
      { headers: authHeader() }
    );
  }
  menuList(formData, page) {
    return axios.post(
      VUE_APP_BASE_URL + "property-unit-list?page=" + page,
      formData,
      {
        headers: authHeader(),
      }
    );
  }
  menuSearch(formData, page) {
    return axios.post(
      VUE_APP_BASE_URL + "property-unit-search?page=" + page,
      formData,
      {
        headers: authHeader(),
      }
    );
  }
  menuSearchByCode(formData) {
    return axios.post(
      VUE_APP_BASE_URL + "property-unit-search-bycode",
      formData,
      {
        headers: authHeader(),
      }
    );
  }
  inventoryTrans() {
    return axios.get(VUE_APP_BASE_URL + "property-unit-transactions", {
      headers: authHeader(),
    });
  }
  store(item, config) {
    return axios.post(VUE_APP_BASE_URL + "property-unit", item, config);
  }
  updateBP(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-update-bp", item, {
      headers: authHeader(),
    });
  }
  updateSP(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-update-sp", item, {
      headers: authHeader(),
    });
  }
  updateWP(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-update-wp", item, {
      headers: authHeader(),
    });
  }
  update(item, config) {
    return axios.put(
      VUE_APP_BASE_URL + "property-unit/" + item.id,
      item,
      config
    );
  }
  update2(item, config) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-update", item, config);
  }
  delete(item) {
    return axios.delete(VUE_APP_BASE_URL + "property-unit/" + item.id, {
      headers: authHeader(),
    });
  }
  restore(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-restore", item, {
      headers: authHeader(),
    });
  }
  reduce(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-reduce", item, {
      headers: authHeader(),
    });
  }
  import(json_data) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-import", json_data, {
      headers: authHeader(),
    });
  }
  restock(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-restock", item, {
      headers: authHeader(),
    });
  }
  waste(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-waste", item, {
      headers: authHeader(),
    });
  }
  transfer(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-transfer", item, {
      headers: authHeader(),
    });
  }
  adjustment(item) {
    return axios.post(VUE_APP_BASE_URL + "property-unit-adjustment", item, {
      headers: authHeader(),
    });
  }
}

export default new PropertyUnitsService();
