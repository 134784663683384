<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-9">
            <select class="form-select d-inline me-2" aria-label="Select order type" @change="PropertySelected"
              v-model="formData.property" style="width: 30%">
              <option value="0" selected="">All property</option>
              <option v-for="(item, i) in propertys" :key="i" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <flat-pickr v-model="formData.from" @change="dateChanged" class="form-control d-inline"
              placeholder="Select Start" style="max-width: 25%" :config="config" />
            To
            <flat-pickr v-model="formData.to" @change="dateChanged" class="form-control d-inline ml-2"
              placeholder="Select Start" style="max-width: 25%" :config="config" />
          </div>
          <div class="col-3 d-flex text-right">
            <download-excel :data="resources" name="expense-analysis-report-bulla" class="ms-auto">
              <button class="btn btn-falcon-primary btn-sm me-1  mb-2 mb-sm-0 d-flex" type="button">
                <span class="fas fa-file-excel m-1"> </span>Excel
              </button>
            </download-excel>
            <a class="btn btn-falcon-info btn-sm mb-2 mb-sm-0 d-flex" :href="pdfUrl" target="_blank">
              <span class="fas fa-file-pdf m-1"> </span> Pdf
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row align-items-center text-center mb-3">
          <div class="col text-sm-center mt-2 mt-sm-0">
            <h4 class="my-2">Expense Analysis Report</h4>
            <h6 class="mb-0">
              {{ user.company.name }}, {{ user.company.address_line1 }}
            </h6>
            <p class="fs--1 mb-0">{{ user.company.address_line2 }}</p>
            <p class="fs--1 mb-0">{{ user.company.address_line3 }}</p>
            <p class="fs--1 mb-0">
              Date : {{ formData.from }} To : {{ formData.to }}
            </p>
          </div>
        </div>
        <div class="table-responsive scrollbar mt-1 fs--1">
          <table class="table table-striped border-bottom">
            <thead class="light">
              <tr class="bg-primary text-white dark__bg-1000">
                <th class="border-0">Date</th>
                <th class="border-0">Property Name</th>
                <th class="border-0">Unit Name</th>
                <th class="border-0">Tenant</th>
                <th class="border-0 text-end">Amount (Ksh)</th>
              </tr>
            </thead>
            <tbody v-if="resources.length > 0">
              <tr v-for="(item, i) in resources" :key="i">
                <td class="mb-0">
                  <td class="mb-0">{{ formatDate(item.created_at) }}</td>
                </td>
                <td class="mb-0">{{ item.property?.name }}</td>
                <td class="mb-0">{{ item.unit?.name }}</td>
                <td class="mb-0" v-if="item.tenant">{{ item.tenant?.surname }} {{ item.tenant?.other_names }}</td>
                <td class="mb-0" v-else>Unit Not lease</td>
                <td class="mb-0 text-end">
                  {{ formatToCurrency(item.amount) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="mb-0 text-nowrap" colspan="5">No records found!</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <table class="table table-sm table-borderless fs--1">
              <tr class="border-bottom border-bottom-2 fw-bolder text-900">
                <th>Revenue Amount:</th>
                <td class="text-end">
                  {{ formatToCurrency(summary.total) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer bg-light">
        <p class="fs--1 mb-0">
          <strong>Report by: </strong>Bulla POS!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import PropertyService from "../services/propertys.service";

const now = new Date();

export default {
  name: "PaymentsReportView",
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
  },
  created() {
    this.getUser();
    this.getAllData();
    this.getAllPropertys();
  },
  data() {
    return {
      summary: [],
      resources: [],
      propertys: [],
      printarea_key: 1,
      preview_modal: false,
      enable_download: false,
      formData: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        property: 0,
      },
      config: {
        dateFormat: "M j, Y",
      },
      user: {},
      config: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        locale: "en-us",
      },
    };
  },
  computed: {
    pdfUrl() {
      this.formData.company_id = this.user.company_id;
      let token = btoa(JSON.stringify(this.formData));
      return process.env.VUE_APP_BASE_URL + 'expense-analysis-pdf/' + token;
    }
  },
  methods: {
    PropertySelected(e) {
      console.log(e.target.value);
      this.formData.property = e.target.value;
      this.getAllData();
    },
    getAllPropertys() {
      return PropertyService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.propertys = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    formatDate(input) {
      return moment(input).format("DD-MM-YYYY HH:mm:ss");
    },

    getUser() {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
      } else {
        this.handleLogout();
      }
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    dateChanged() {
      console.log("Date changed..");
      console.log(this.formData);
      setTimeout(() => {
        this.getAllData();
      }, 500);
    },
    async getToday() {
      this.formData.from = await moment(new Date()).format("YYYY-MM-DD");
      this.formData.to = await moment(new Date()).format("YYYY-MM-DD");
      this.getAllData();
    },
    printReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = false;
      this.preview_modal = true;
      this.printarea_key += 1;
      this.report_name = "Payment Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    generateReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = true;
      this.preview_modal = false;
      this.printarea_key += 1;
      this.report_name = "Payment Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    onProgress(e) {
      console.log(e);
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },

    getAllData() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.expenseAnalysis(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data.data);
            this.resources = response.data.data.records;
            this.summary = response.data.data.summary;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
