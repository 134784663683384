// Data sevice
import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class TenantMemberService {
  all(property) {
    return axios.get(VUE_APP_BASE_URL + "tenant-member-all/" + property, {
      headers: authHeader(),
    });
  }
  index() {
    return axios.get(VUE_APP_BASE_URL + "tenant-member", {
      headers: authHeader(),
    });
  }
  store(item, config) {
    return axios.post(VUE_APP_BASE_URL + "tenant-member", item, config);
  }
  update(item, config) {
    return axios.put(
      VUE_APP_BASE_URL + "tenant-member/" + item.id,
      item,
      config
    );
  }
  delete(item) {
    return axios.delete(VUE_APP_BASE_URL + "tenant-member/" + item.id, {
      headers: authHeader(),
    });
  }
  getArchived() {
    return axios.get(VUE_APP_BASE_URL + "tenant-member-archived", {
      headers: authHeader(),
    });
  }
  restore(item) {
    return axios.post(VUE_APP_BASE_URL + "tenant-member-restore", item, {
      headers: authHeader(),
    });
  }
}

export default new TenantMemberService();
