<template>
  <div>
    <div class="card mb-3" id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-users text-warning"></i>
              All Leases
            </h5>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <button class="btn btn-falcon-warning ms-2 my-auto" type="button" @click="showAddForm">
                <span class="fas fa-plus-circle" data-fa-transform=""></span><span
                  class="d-none d-sm-inline-block ms-1">Add New Lease</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- START new form -->
        <div v-if="addForm">
          <div class="modal-content border rounded">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" @click="hideAddForm"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">{{ formData.id ? 'Edit' : 'New' }} Lease</h4>
              </div>
              <div class="p-4">
                <div class="row">
                  <div class="col-12">
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">{{ formData.id ? 'Selected' : 'Select' }}
                            Tenant</label>
                          <Select2 v-if="!formData.id" class="d-block border"
                            style="width: 100%; max-height: 38px !important" placeholder="Select a tenant..."
                            v-model="formData.tenant_id" :options="tenants" :class="v$.formData.tenant_id.$dirty &&
                              v$.formData.tenant_id.$invalid
                              ? `is-invalid`
                              : v$.formData.tenant_id.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <input v-else disabled class="form-control" v-model="formData.tenant_name">
                          <div v-for="error of v$.formData.tenant_id.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Entry Date</label>
                          <flat-pickr @change="onEntryDateChange" v-model="formData.entry_date"
                            class="d-block form-control" placeholder="e.g 2024-01-31" style="width: 100%" :config="config"
                            :class="v$.formData.entry_date.$dirty &&
                              v$.formData.entry_date.$invalid
                              ? `is-invalid`
                              : v$.formData.entry_date.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <div v-for="error of v$.formData.entry_date.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>

                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">{{ formData.id ? 'Selected' : 'Select' }}
                            Property</label>
                          <Select2 v-if="!formData.id" class="d-block border"
                            style="width: 100%; max-height: 38px !important" placeholder="Select a property..."
                            v-model="formData.property_id" :options="properties" @select="mySelectEvent($event)" :class="v$.formData.property_id.$dirty &&
                              v$.formData.property_id.$invalid
                              ? `is-invalid`
                              : v$.formData.property_id.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <input v-else disabled class="form-control" v-model="formData.property_name">
                          <div v-for="error of v$.formData.property_id.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>

                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">{{ formData.id ? 'Selected' : 'Select' }}
                            Unit</label>
                          <Select2 v-if="!formData.id" class="d-block border"
                            style="width: 100%; max-height: 38px !important" placeholder="Select a unit..."
                            v-model="formData.unit_id" :options="property_units" :class="v$.formData.unit_id.$dirty &&
                              v$.formData.unit_id.$invalid
                              ? `is-invalid`
                              : v$.formData.unit_id.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <input v-else disabled class="form-control" v-model="formData.unit_name">
                          <div v-for="error of v$.formData.unit_id.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>

                        <div class="mb-3 col-4">
                          <label class="col-form-label" for="recipient-name">Lease Duration(Months)</label>
                          <input @change="onEntryDateChange" class="form-control" id="recipient-name" type="number"
                            placeholder="e.g 21" v-model="formData.lease_duration" :class="v$.formData.lease_duration.$dirty &&
                              v$.formData.lease_duration.$invalid
                              ? `is-invalid`
                              : v$.formData.lease_duration.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <div v-for="error of v$.formData.lease_duration.$errors" :key="error.$uid"
                            class="invalid-feedback" style="color: red">
                            This field is invalid
                          </div>
                        </div>

                        <div class="mb-3 col-4">
                          <label class="col-form-label" for="recipient-name">Exit Date</label>
                          <flat-pickr disabled v-model="formData.exit_date" class="d-block form-control"
                            placeholder="e.g 2024-01-31" style="width: 100%" :config="config" :class="v$.formData.exit_date.$dirty &&
                              v$.formData.exit_date.$invalid
                              ? `is-invalid`
                              : v$.formData.exit_date.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <div v-for="error of v$.formData.exit_date.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>

                        <div class="mb-3 col-4">
                          <label class="col-form-label" for="recipient-name">Renewal Notice Date</label>
                          <flat-pickr disabled v-model="formData.renewal_notice_date" class="d-block form-control"
                            placeholder="e.g 2024-01-31" style="width: 100%" :config="config" :class="v$.formData.renewal_notice_date.$dirty &&
                              v$.formData.renewal_notice_date.$invalid
                              ? `is-invalid`
                              : v$.formData.renewal_notice_date.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <div v-for="error of v$.formData.renewal_notice_date.$errors" :key="error.$uid"
                            class="invalid-feedback" style="color: red">
                            This field is invalid
                          </div>

                        </div>
                        <div v-for="error of v$.formData.renewal_notice_date.$errors" :key="error.$uid"
                          class="invalid-feedback" style="color: red">
                          This field is invalid
                        </div>

                        <div class="mb-3 col-4">
                          <label class="col-form-label" for="recipient-name">Nature of Business</label>
                          <input class="form-control" id="recipient-name" type="text" placeholder="e.g Liquor Store"
                            v-model="formData.nature_of_business" :class="v$.formData.nature_of_business.$dirty &&
                              v$.formData.nature_of_business.$invalid
                              ? `is-invalid`
                              : v$.formData.nature_of_business.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <div v-for="error of v$.formData.nature_of_business.$errors" :key="error.$uid"
                            class="invalid-feedback" style="color: red">
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-4">
                          <label class="col-form-label" for="recipient-name">Actual Rent Amount</label>
                          <input class="form-control" id="recipient-name" type="number" placeholder="e.g 20000"
                            v-model="formData.rent_amount" :class="v$.formData.rent_amount.$dirty &&
                              v$.formData.rent_amount.$invalid
                              ? `is-invalid`
                              : v$.formData.rent_amount.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <div v-for="error of v$.formData.rent_amount.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-4">
                          <label class="col-form-label" for="message-text">Deposit Amount</label>
                          <input class="form-control" id="recipient-name" type="number" placeholder="e.g 50000"
                            v-model="formData.deposit_amount" :class="v$.formData.deposit_amount.$dirty && v$.formData.deposit_amount.$invalid
                              ? `is-invalid`
                              : v$.formData.deposit_amount.$dirty
                                ? `is-valid`
                                : ``
                              " />
                          <div v-for="error of v$.formData.deposit_amount.$errors" :key="error.$uid"
                            class="invalid-feedback" style="color: red">
                            This field is invalid
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer m-4">
              <button class="btn me-auto border" @click="hideAddForm" aria-label="Close">
                Close
              </button>
              <button v-if="formData.id" class="btn btn-primary" role="button" @click="updateResource">
                <i class="fa fa-save me-2"></i>Update
              </button>
              <button v-else class="btn btn-success" role="button" @click="saveResource">
                <i class="fa fa-save me-2"></i>Save
              </button>
            </div>
          </div>
        </div>
        <!-- END new form -->

        <div v-else class="table-responsive scrollbar">
          <table class="table table-bordered table-striped fs--1 mb-0">
            <thead class="bg-200 text-900">
              <tr>
                <th>Tenant Name</th>
                <th>Phone</th>
                <th>Property</th>
                <th>Unit</th>
                <th>Entry</th>
                <th>Deposit</th>
                <th>Rent</th>
                <th>Status</th>
                <th>Assigned</th>
                <th>Action</th>
              </tr>
            </thead>
            <p v-if="resources.length <= 0" class="ml-4 mt-3">
              No active lease found!
            </p>
            <tbody v-else>
              <tr v-for="item in resources" :key="item.id">
                <td>{{ item.tenant.salutation }}. {{ item.tenant.surname }} {{ item.tenant.other_names }}</td>
                <td>{{ item.tenant.phone }}</td>
                <td>{{ item.property.name }}</td>
                <td>{{ item.unit.name }}</td>
                <td>{{ formatDateHuman(item.entry_date) }}</td>
                <td>{{ item.deposit_amount }}</td>
                <td>{{ item.rent_amount }}</td>
                <td>
                  <button class="btn btn-sm" :class="item.active == 1 ? 'btn-falcon-success' : 'btn-falcon-danger'">
                    {{ item.active == 1 ? "Active" : "Inactive" }}
                  </button>
                </td>
                <td>
                  <button class="btn btn-sm"
                    :class="item.tenant.assigned == 1 ? 'btn-falcon-success' : 'btn-falcon-warning'">
                    {{ item.tenant.assigned == 1 ? "Assigned" : "Unassigned" }}
                  </button>
                </td>
                <td>
                  <div class="dropdown font-sans-serif position-static">
                    <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button"
                      id="order-dropdown-0" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true"
                      aria-expanded="false">
                      <span class="fas fa-ellipsis-h fs--1"></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                      <div class="py-2">
                        <a class="dropdown-item" role="button" @click="editItem(item)" data-bs-toggle="modal"
                          data-bs-target="#edit-modal">
                          <i class="fa fa-edit me-2"></i>Edit</a>
                        <div class="dropdown-divider"></div>
                        <a v-if="user.permissions.includes('revoke-lease')" class="dropdown-item text-danger"
                          role="button" @click="revokeLease(item)">
                          <i class="fa fa-trash me-2"></i>Revoke</a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page"
              :records="records"
              :per-page="per_page"
              @paginate="myCallback"
            />
          </div> -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import TenantService from "../services/tenant.service";
import Pagination from "v-pagination-3";
import PropertyService from "../services/propertys.service";
import LeaseService from "../services/lease.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "NewLeaseView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        lease_duration: { required },
        unit_id: { required },
        entry_date: { required },
        nature_of_business: { required },
        renewal_notice_date: { required },
        exit_date: { required },
        rent_amount: { required },
        tenant_id: { required },
        property_id: { required },
        deposit_amount: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    Pagination,
    EasyDataTable,
  },
  data() {
    return {
      addForm: false,
      tenants: [],
      properties: [],
      property_units: [],
      per_page: 25,
      current_page: 0,
      records: 0,
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "phone", "deposit_amount"],
      headers: [
        { text: "#", value: "ID", sortable: true, widh: 10 },
        { text: "Name", value: "NAME", sortable: true },
        { text: "Phone", value: "phone", sortable: true },
        { text: "deposit_amount", value: "deposit_amount", sortable: true },
        { text: "Registered at", value: "CREATED_AT", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    this.getAllLeases();
    this.getTenants();
    this.getProperties();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    clearModals() {
      $("#edit-modal").removeClass("fade").hide();
      $("#add-modal").removeClass("fade").hide();
      $(".modal-backdrop").remove();
    },
    onEntryDateChange() {
      let entryDate = new Date(this.formData.entry_date);
      this.formData.exit_date = this.getExitDate(entryDate, this.formData.lease_duration);
      let exitDate = new Date(this.formData.exit_date);
      this.formData.renewal_notice_date = this.getNoticeDate(exitDate);
    },
    getExitDate(date, months) {
      date.setMonth(date.getMonth() + months);
      return date;
    },
    getNoticeDate(date) {
      date.setMonth(date.getMonth() - 3);
      return date;
    },
    mySelectEvent(poperty) {
      console.log(poperty)
      console.log(poperty.id)
      this.getUnits(poperty.id)
    },
    getUnits(id) {
      this.$store.commit("SET_LOADING", true);
      return PropertyService.getUnits(id).then(
        (response) => {
          if (response.data.code == 200) {
            this.property_units = response.data.data.map((el) => {
              return {
                id: el.id,
                text: el.name,
              };
            });
            console.log(this.units);
          } else {
            this.property_units = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getProperties() {
      this.$store.commit("SET_LOADING", true);
      return PropertyService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.properties = response.data.data.map((el) => {
              return {
                id: el.id,
                text: el.name + ' - ' + el.address,
              };
            });
          } else {
            this.properties = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getTenants() {
      return TenantService.getNew().then(
        (response) => {
          if (response.data.status == "success") {
            this.tenants = response.data.data.map((el) => {
              return {
                id: el.id,
                text: el.salutation + '. ' + el.surname + ' ' + el.other_names + ', ' + el.phone,
              };
            });
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    showAddForm() {
      this.formData = {};
      this.addForm = true;
    },
    hideAddForm() {
      this.addForm = false;
    },
    editItem(item) {
      this.formData = item;
      this.formData.tenant_name = item.tenant.surname + ' ' + item.tenant.other_names;
      this.formData.property_name = item.property.name;
      this.formData.unit_name = item.unit.name;
      console.log(item)
      this.addForm = true;
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.lease_duration.$touch();
      this.v$.formData.tenant_id.$touch();
      this.v$.formData.unit_id.$touch();
      this.v$.formData.entry_date.$touch();
      this.v$.formData.nature_of_business.$touch();
      this.v$.formData.renewal_notice_date.$touch();
      this.v$.formData.exit_date.$touch();
      this.v$.formData.rent_amount.$touch();
      this.v$.formData.deposit_amount.$touch();
      this.v$.formData.exit_date.$touch();
      this.v$.formData.property_id.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      LeaseService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getAllLeases();
            this.hideAddForm();
            this.getTenants();
          } else {
            console.log(response.data.error);
            toast.error(response.data.message || response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error.response.data.message + ' : ' + JSON.stringify(error.response.data.errors), {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.v$.formData.lease_duration.$touch();
      this.v$.formData.tenant_id.$touch();
      this.v$.formData.unit_id.$touch();
      this.v$.formData.entry_date.$touch();
      this.v$.formData.nature_of_business.$touch();
      this.v$.formData.renewal_notice_date.$touch();
      this.v$.formData.exit_date.$touch();
      this.v$.formData.rent_amount.$touch();
      this.v$.formData.deposit_amount.$touch();
      this.v$.formData.exit_date.$touch();
      this.v$.formData.property_id.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      LeaseService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getAllLeases();
            this.clearModals();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error.response.data.message + ' : ' + JSON.stringify(error.response.data.errors), {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    revokeLease(resource) {
      let sure = confirm("Are you sure you want to revoke this lease? you cannot undo this action.");
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        LeaseService.delete(resource.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getAllLeases();
              this.getTenants();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getAllLeases() {
      this.$store.commit("SET_LOADING", true);
      return LeaseService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
