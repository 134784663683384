// Data sevice
import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class PropertyService {
  index() {
    return axios.get(VUE_APP_BASE_URL + "property", { headers: authHeader() });
  }
  getArchived() {
    return axios.get(VUE_APP_BASE_URL + "property-archived", {
      headers: authHeader(),
    });
  }
  show(id) {
    return axios.get(VUE_APP_BASE_URL + "property/" + id, {
      headers: authHeader(),
    });
  }
  store(item, config) {
    return axios.post(VUE_APP_BASE_URL + "property", item, config);
  }
  update(item, config) {
    return axios.put(VUE_APP_BASE_URL + "property/" + item.id, item, config);
  }
  delete(resource) {
    return axios.delete(VUE_APP_BASE_URL + "property/" + resource.id, {
      headers: authHeader(),
    });
  }
  restore(item) {
    return axios.post(VUE_APP_BASE_URL + "property-restore", item, {
      headers: authHeader(),
    });
  }
  storeSingle(resource) {
    return axios.post(VUE_APP_BASE_URL + "property-store", resource, {
      headers: authHeader(),
    });
  }
  getUnits(id) {
    return axios.get(VUE_APP_BASE_URL + "property-units/" + id, {
      headers: authHeader(),
    });
  }
}

export default new PropertyService();
