// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class TenantService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'tenant', { headers: authHeader() })
    }
    getNew() {
        return axios.get(VUE_APP_BASE_URL + 'new-tenants', { headers: authHeader() })
    }
    statement(formData){
        return axios.post(VUE_APP_BASE_URL + 'tenant-statement', formData, { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'tenant', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'tenant/' + resource.id, resource, { headers: authHeader() })
    }
    show(id) {
        return axios.get(VUE_APP_BASE_URL + 'tenant/' + id, { headers: authHeader() })
    }
    delete(id) {
        return axios.delete(VUE_APP_BASE_URL + 'tenant/' + id, { headers: authHeader() })
    }
    getArchived() {
      return axios.get(VUE_APP_BASE_URL + "tenant-archived", {
        headers: authHeader(),
      });
    }
    restore(item) {
      return axios.post(VUE_APP_BASE_URL + "tenant-restore", item, {
        headers: authHeader(),
      });
    }
}

export default new TenantService()