<template>
  <div>
    <div class="row g-2">
      <div class="col-12">
        <div class="card bg-transparent-50 overflow-hidden">
          <div class="col-sm-auto d-flex align-items-center">
            <img class="ms-n2" src="assets/img/illustrations/crm-bar-chart.png" alt="" width="90" />

            <div>
              <h6 class="text-primary fw-bold mb-0">
                {{ getGreetings() }}

                <span class="text-info fw-medium">{{ user.surname }}!</span>
              </h6>

              <!-- <p class="fs--1">Here’s what happening with your store today</p> -->
            </div>

            <img class="ms-n4 d-md-none d-lg-block" src="assets/img/illustrations/crm-line-chart.png" alt=""
              width="150" />
          </div>
          <div class="bg-holder d-none d-md-block bg-card z-index-1" style="
              background-image: url('../assets/img/illustrations/crm-bar-chart.png');
              background-position: right bottom;
              z-index: -1;
            "></div>
        </div>
      </div>
      <!-- Filters -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="card bg-transparent-50 overflow-hidden p-2" style="height:690px">
          <h6 class="text-primary">Filters</h6>
          <div class="">
            <h6>Property</h6>
            <select class="form-select d-inline" aria-label="Select order type" @change="PropertySelected"
              v-model="formData.property">
              <option value="0" selected="">All Properties</option>

              <option v-for="(item, i) in propertys" :key="i" :value="item.id">
                {{ item.name }}
              </option>

              y
            </select>
          </div>
          <div class="mt-3">
            <h6>Date</h6>
            <VDatePicker v-model.range="range" mode="dateTime" class="mx-auto" style="width: 100%" :key="calendar_key" />
          </div>
        </div>
      </div>
      <!-- Dash  -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
        <div class="card bg-transparent-50 overflow-hidden p-2">
          <h6 class="text-primary">Summary</h6>
          <div class="row g-2">
            <!-- start: first row -->
            <div class="col-6 col-sm-3" role="button" @click="goToProducts">
              <div class="card overflow-hidden border-top border-bottom border-3 border-primary">
                <div class="card-body position-relative">
                  <h6>Total Units</h6>

                  <count-up class="display-4 fs-3 fw-normal font-sans-serif text-primary" :end-val="summary?.totalUnits"
                    :start-val="0"></count-up>
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-3" role="button" @click="goToUsers">
              <div class="card overflow-hidden border-top border-bottom border-3 border-warning">
                <div class="card-body position-relative">
                  <h6>Total Occupied</h6>

                  <count-up class="display-4 fs-3 fw-normal font-sans-serif text-warning" :end-val="summary?.totalOccupied"
                    :start-val="0"></count-up>
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-3" role="button" @click="goToTenants">
              <div class="card overflow-hidden border-top border-bottom border-3 border-info">
                <div class="card-body position-relative" :key="summary?.tenants">
                  <h6>Tenants</h6>

                  <count-up class="display-4 fs-3 fw-normal font-sans-serif text-info" :end-val="summary?.tenants"
                    :start-val="0"></count-up>
                </div>
              </div>
            </div>

            <div class="col-6 col-sm-3" role="button" @click="goToOrders">
              <div class="card overflow-hidden border-top border-bottom border-3 border-success">
                <div class="card-body position-relative">
                  <h6>Total Vacant</h6>

                  <count-up class="display-4 fs-3 fw-normal font-sans-serif text-success" :end-val="summary?.totalVacant"
                    :start-val="0"></count-up>
                </div>
              </div>
            </div>
            <!-- end: first row -->

            <!-- start: second row -->
            <div class="col-sm-3">
              <div class="card overflow-hidden" style="min-width: 12rem">
                <div class="bg-holder bg-card" style="
                    background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                  "></div>
                <div class="card-body position-relative" :key="summary?.tenants">
                  <h6>
                    Cost of properties
                  </h6>

                  <count-up class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-success"
                    :end-val="summary?.totalCostOfProperty" :start-val="0"></count-up>
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="card overflow-hidden" style="min-width: 12rem">
                <div class="bg-holder bg-card" style="
                    background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                  "></div>
                <div class="card-body position-relative">
                  <h6>
                    Cost of occupation
                  </h6>

                  <count-up class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info"
                    :end-val="summary?.totalCostOfOccupation" :start-val="0"></count-up>
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="card overflow-hidden" style="min-width: 12rem">
                <div class="bg-holder bg-card" style="
                    background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                  "></div>
                <div class="card-body position-relative">
                  <h6>
                    Cost of vacancies
                  </h6>

                  <count-up class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning"
                    :end-val="summary?.totalCostOfVacancies" :start-val="0"></count-up>
                </div>
              </div>
            </div>

            <div class="col-sm-3">
              <div class="card overflow-hidden" style="min-width: 12rem">
                <div class="bg-holder bg-card" style="
                    background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                  "></div>
                <div class="card-body position-relative" :key="summary?.totalPaymentsReceived">
                  <h6>
                    Payments Received
                  </h6>

                  <count-up class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-primary"
                    :end-val="summary?.totalPaymentsReceived" :start-val="0"></count-up>
                </div>
              </div>
            </div>

            <!-- end: second row -->

            <!-- Third row  -->

            <div class="col-md-6">
              <div class="card h-md-100 h-100">
                <div class="card-body" :key="chart_key">
                  <div class="row h-100 justify-content-between g-0">
                    <h6 class="mt-1">Cost Value Vs Occupation Value</h6>

                    <div class="fs--2 mt-1" v-if="stock_analysis">
                      <vue-echarts :option="stock_value_chart_option" style="height: 335px; width: 100%" ref="chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="card h-md-100 h-100">
                <div class="card-body" :key="chart_key">
                  <div class="row h-100 justify-content-between g-0">
                    <h6 class="mt-1">Payments Analysis</h6>
                    <div class="fs--2 mt-3" v-if="weekly_analysis">
                      <vue-echarts :option="sales_chart_option" style="height: 350px; width: 100%" ref="chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import OrdersService from "../services/orders.service";
import ReportService from "../services/report.service";
import PropertyService from "../services/propertys.service";
// echarts
import { VueEcharts } from "vue3-echarts";
import { ref } from "vue";

// seven days ago from now
const currentDate = new Date();
const someDaysAgo = new Date(currentDate);
someDaysAgo.setDate(currentDate.getDate() - 0);

const range = ref({
  start: moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
  end: moment().endOf("week").format("YYYY-MM-DD HH:mm:ss"),
});

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    VueEcharts,
    EasyDataTable,
  },
  data() {
    return {
      editedItem: {},
      selected: [],
      propertys: [],
      selected_items: "",
      chart_key: 1,
      calendar_key: 1,
      refreshed: 0,
      summary: [],
      weekly_analysis: [],
      stock_analysis: [],
      stock_analysis_report: [],
      stock_value_chart_option: {},
      market_share_chart_option: {},
      sales_chart_option: {},
      best_seller_option: {},
      resources: [],
      tenants: [],
      range: range,
      formData: {
        start: moment().startOf("week").format("YYYY-MM-DD  HH:mm:ss"),
        end: moment().endOf("week").format("YYYY-MM-DD  HH:mm:ss"),
        property: 0,
      },
      config: {
        dateFormat: "M j, Y",
      },
      headers: [
        { text: "Lease", value: "id", sortable: true, width: "10" },
        { text: "Date", value: "date", sortable: true },
        { text: "Served by", value: "owner", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Tenant Detail", value: "tenant", sortable: true },
        { text: "Due", value: "DUE_AMOUNT", sortable: true },
        { text: "Paid", value: "PAID_AMOUNT", sortable: true },
        { text: "Location", value: "location", align: "end", width: "20" },
        {
          text: "Amount",
          value: "TOTAL_PRICE",
          width: 120,
          align: "right",
          sortable: true,
        },
        { text: "Action", value: "action" },
      ],
    };
  },
  watch: {
    weekly_analysis: {
      handler(newVal, oldVal) {
        this.chart_key += 1;
      },
      deep: true,
    },
    range: {
      handler(newVal, oldVal) {
        this.calendar_key += 1;
        const start = moment(newVal.start).format("YYYY-MM-DD HH:mm:ss");
        const end = moment(newVal.end).format("YYYY-MM-DD HH:mm:ss");
        this.formData.start = start;
        this.formData.end = end;
        this.getAllData();
      },
      deep: true,
    },
  },
  async created() {
    // this.getAllOrders();
    // this.getOpenOrders();
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getAllData();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    best_seller_report() {
      this.$store.commit("SET_LOADING", true);
      if (this.$store.state.analytics.best_seller != undefined) {
        this.$store.commit("SET_LOADING", false);
        return this.$store.state.analytics.best_seller.data;
      } else {
        return null;
      }
    },
  },
  methods: {
    async getAllData() {
      this.$store.commit("SET_LOADING", true);
      await this.getDailyAnalysis();
      await this.getStockAnalysis();
      await this.getAllPropertys();
      await this.getGeneralAnalysis();
      this.$store.commit("SET_LOADING", false);
    },
    PropertySelected(e) {
      this.formData.property = parseInt(e.target.value);
      console.log("Selected order type", this.formData.property);
      this.getAllData();
    },
    getGeneralAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.getGeneralAnalysis(this.formData).then(
        (response) => {
          console.log("weekly", response);
          if (response.code == 200) {
            this.weekly_analysis = response.data;
            this.market_share_chart_option = {
              title: {
                text: "Market share",
                subtext: "No of tickets sold by user",
                left: "center",
              },
              tooltip: {
                enabled: true,
                trigger: "item",
              },
              legend: {
                orient: "vertical",
                left: "left",
              },
              series: [
                {
                  name: "Tickets sold",
                  type: "pie",
                  radius: "60%",
                  data: this.weekly_analysis.market_share,
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            };
            this.sales_chart_option = {
              title: {
                text: "Revenue Chart",
                subtext: "Revenue collected this week",
                left: "right",
              },
              xAxis: {
                type: "category",
                data: this.weekly_analysis.weekly_sales_days,
              },
              yAxis: {
                type: "value",
              },
              tooltip: {
                enabled: true,
                trigger: "axis",
              },
              series: [
                {
                  data: this.weekly_analysis.weekly_sales,
                  type: "bar",
                  showBackground: true,
                  backgroundStyle: {
                    color: "rgba(180, 180, 180, 0.2)",
                  },
                },
              ],
            };
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getOpenOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.open().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getClosedOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.closed().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getVoidedOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.voided().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getOpenOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.open().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getGreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    // --start---------------------------
    goToProducts() {
      this.$router.push("/property-unit");
    },
    goToOrders() {
      this.$router.push("/pos-open-order");
    },
    goToUsers() {
      this.$router.push("/users");
    },
    goToTenants() {
      this.$router.push("/store");
    },
    goToStoreView() {
      this.$router.push("/store");
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.getDailyAnalysis(this.formData).then(
        (response) => {
          if (response.code == 200) {
            this.summary = response.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getStockAnalysis() {
      return ReportService.getStockAnalysis(this.formData).then(
        (response) => {
          console.log("stock_analysis_report", response.data);
          if (response.code == 200) {
            this.stock_analysis_report = response.data;
          }
          let data = this.stock_analysis_report;
          console.group("dataset");
          let dataset = [
            {
              name: "Total Cost",
              value: data.stock_cost_value,
            },
            {
              name: "Occupation Value",
              value: data.stock_price_value,
            },
          ];
          this.stock_value_chart_option = {
            title: {
              text: "Cost Analysis",
              subtext: "All cost (Ksh)",
              left: "right",
            },
            tooltip: {
              enabled: true,
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: [
              {
                name: "Stock Analysis",
                type: "pie",
                radius: "60%",
                data: dataset,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getAllPropertys() {
      return PropertyService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.propertys = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    // --end---------------------------
  },
};
</script>
