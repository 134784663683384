import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class LeaseService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'lease', { headers: authHeader() });
    }
    order(formData) {
        return axios.post(VUE_APP_BASE_URL + 'lease-order', formData, { headers: authHeader() });
    }
    charge(formData) {
        return axios.post(VUE_APP_BASE_URL + 'lease-charge', formData, { headers: authHeader() });
    }
    store(formData) {
        return axios.post(VUE_APP_BASE_URL + 'lease', formData, { headers: authHeader() });
    }
    update(formData) {
        return axios.put(VUE_APP_BASE_URL + 'lease/' + formData.id, formData, { headers: authHeader() });
    }
    delete(id) {
        return axios.delete(VUE_APP_BASE_URL + 'lease/' + id, { headers: authHeader() });
    }
    payLease(formData) {
        return axios.post(VUE_APP_BASE_URL + 'lease-pay', formData, { headers: authHeader() });
    }
    mergeLease(formData) {
        return axios.post(VUE_APP_BASE_URL + 'lease-merge', formData, { headers: authHeader() });
    }
    closeLease(lease_id){
        return axios.get(VUE_APP_BASE_URL + 'lease-close/' + lease_id, { headers: authHeader() });
    }
    voidLease(lease_id){
        return axios.get(VUE_APP_BASE_URL + 'lease-void/' + lease_id, { headers: authHeader() });
    }
    giftLease(lease_id){
        return axios.get(VUE_APP_BASE_URL + 'lease-gift/' + lease_id, { headers: authHeader() });
    }
    change(resource) {
        return axios.post(VUE_APP_BASE_URL + 'lease-change-tenant', resource, { headers: authHeader() })
    }
    registerPayment(data) {
        return axios.post(VUE_APP_BASE_URL + 'receive-payment', data, { headers: authHeader() })
    }
}

export default new LeaseService()