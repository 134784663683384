<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-sm-4 col-xs-4 align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-shopping-cart"></i>
              Stocks Daily Sheet
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Generate stock sheet reports
            </p>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <download-excel
                class="ms-auto"
                :data="resources"
                name="stock-master-report-bulla"
              >
                <button
                  class="btn btn-falcon-primary btn-sm me-1 mb-2 mb-sm-0 d-flex"
                  type="button"
                >
                  <span class="fas fa-file-excel m-1"> </span>Excel
                </button>
              </download-excel>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Start of invoice  -->
        <div class="card mb-3">
          <div class="card-body">
            <div class="row align-items-center text-center mb-3">
              <div class="col-sm-6 text-sm-start">
                <!-- <img :src="user.company.logo" alt="invoice" width="150" /> -->
              </div>
              <div class="col text-sm-end mt-3 mt-sm-0">
                <h2 class="mb-3">Stocks Daily Report</h2>
                <h5>{{ company.name }}</h5>
                <p class="fs--1 mb-0">
                  {{ company.address_line1 }}, {{ company.address_line2
                  }}<br />{{ company.address_line3 }}
                </p>
              </div>
              <div class="col-12">
                <hr />
              </div>
            </div>

            <!-- Menu items table  -->
            <div
              id="tableExample3"
              data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
            >
              <div class="row justify-content-start g-0">
                <div class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3">
                  <select
                    class="form-select"
                    id="recipient-name"
                    type="text"
                    @change="selectProperty"
                    style="width: auto"
                  >
                    <option
                      v-for="(item, i) in propertys"
                      :key="i"
                      :value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div
                  class="col-12 col-md-4 col-sm-12 col-xs-12 mb-3 d-flex ms-auto"
                >
                  <div class="input-group ms-auto me-2">
                    <input
                      class="form-control form-control-sm shadow-none search"
                      type="search"
                      placeholder="Search..."
                      aria-label="search"
                      v-model="search_term"
                      v-on:keyup="searchItem"
                    />
                    <div class="input-group-text bg-transparent">
                      <span class="fa fa-search fs--1 text-600"></span>
                    </div>
                  </div>
                  <button class="btn btn-falcon-warning mx-1" @click="goToPOS">
                    POS
                  </button>
                </div>
              </div>
              <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped fs--1 mb-0">
                  <thead class="bg-200 text-900">
                    <tr>
                      <th class="sort" width="5%">#</th>
                      <th class="sort">Name</th>
                      <th class="sort">Category</th>
                      <th class="sort">Opening</th>
                      <th class="sort">Sales</th>
                      <th class="sort">Restock</th>
                      <th class="sort">Closing</th>
                      <th class="sort">Units</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr v-for="(item, i) in resources" :key="item.id">
                      <td>{{ item.id }}</td>
                      <td>
                        <div class="my-0">
                          <strong>{{ item.name.toUpperCase() }}</strong>
                          <p class="mb-0 text-500 text-wrap">
                            {{ item.email }}
                          </p>
                        </div>
                      </td>
                      <td>{{ item.group }}</td>
                      <td>
                        {{ item.opening_stock }}
                      </td>
                      <td>{{ item.total_sales }}</td>
                      <td>{{ item.total_restock }}</td>
                      <td>{{ item.stock_amount }}</td>
                      <td>{{ item.unit_name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- End Menu items table  -->
          </div>
          <div class="card-footer bg-light">
            <p class="fs--1 mb-0">
              <strong>Notes: </strong>We really appreciate your business and if
              there’s anything else we can do, please let us know!
            </p>
          </div>
        </div>
        <!-- End of invoice  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import Pagination from "v-pagination-3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import VueBarcode from "@chenfengyuan/vue-barcode";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import PropertyUnitsService from "../services/property_unit.service";
import MenuGroupService from "../services/menu_group.service";
import PropertyUnitSizeService from "../services/property_unit_size.service";
import PropertyService from "../services/propertys.service";
import readXlsFile from "read-excel-file";
import exportXlsFile from "export-from-json";
const now = new Date();
export default {
  mixins: [mixins],
  name: "TenantsView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        price: { required },
        group_id: { required },
        buy_price: { required },
        stock_amount: { required },
        reorder_level: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    Pagination,
    VueBarcode,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      per_page: 18,
      current_page: 0,
      records: 0,
      search_term: "",
      file: "",
      property: "",
      filename: "",
      selected: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      propertys: [],
      selected_items: "",
      import_resources: [],
      property_unit_sizes: [],
      productCategories: [],
      selected_category: "",
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        barcode: "",
        unit_name: "",
        unit_name: "",
        group: "",
        propertys: [],
        barcode: "",
        reorder_level: 0,
      },
      resources: [],
      orders_table_key: 0,
      show_location_error: false,
      searchField: ["id", "name", "price"],
      headers: [
        { text: "#", value: "id", sortable: true, width: 10 },
        { text: "Photo", value: "image", sortable: true, width: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Group", value: "group_id", sortable: true },
        { text: "B.Price", value: "buy_price", sortable: true },
        { text: "S.Price", value: "price", sortable: true },
        { text: "Group", value: "group", sortable: true },
        { text: "Barcode", value: "barcode", sortable: true },
        { text: "Stock Amount", value: "stock_amount", sortable: true },
        { text: "Units", value: "unit_name", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await this.getAllPropertys();
    await this.getGroups();
    await this.getPropertyUnitSize();
    this.$store.commit("SET_LOADING", false);
    this.getAllPropertyUnits(1);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
    company() {
      return JSON.parse(localStorage.getItem("user")).company;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    getOpeningStock(stock_amount, total_restock, total_sales) {
      let b4Restock = stock_amount - total_restock;
      let openingStock = b4Restock + total_sales;
      return openingStock;
    },
    myCallback(page) {
      this.page = page;
      this.getAllPropertyUnits(page);
    },
    importExcel() {
      const input = document.getElementById("import-excel");
      readXlsFile(input.files[0]).then((rows) => {
        this.import_resources = rows.filter((item, index) => index != 0);
      });
    },
    searchItem() {
      this.page = 1;
      this.current_page = 1;
      let formData = {
        search_term: this.search_term,
      };
      this.$store.commit("SET_LOADING", true);
      return PropertyUnitsService.menuSearch(formData, this.current_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    goToBarcode() {
      this.$router.push("/barcode");
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    addNew() {
      this.formData = {
        group_id: "",
        active: 0,
        visible: 1,
        barcode: "",
        unit_name: "",
        group: "",
        propertys: [],
      };
      this.file = "";
      this.filename = "";
      this.show_location_error = false;
    },
    editItem(item) {
      console.log(item);
      this.show_location_error = false;
      this.formData = item;
      this.formData.propertys = [];
    },
    addToItems(type_id) {
      if (
        this.formData.propertys &&
        this.formData.propertys.includes(type_id)
      ) {
        this.formData.propertys = this.formData.propertys.filter(
          (el) => el != type_id
        );
      } else {
        this.formData.propertys.push(type_id);
      }
    },
    onFileChange(e) {
      console.log(e.target.files[0]);
      this.filename = "Selected File: " + e.target.files[0].name;
      this.file = e.target.files[0];
    },

    async selectProperty(e) {
      this.property = e.target.value;
      await this.getAllPropertyUnits(this.page);
      this.$store.commit("SET_LOADING", false);
    },
    getAllPropertys() {
      return PropertyService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.propertys = response.data.data;
            this.property = response.data.data.at(0).id;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    sortItems(list) {
      return list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItems() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllPropertyUnits(page) {
      this.$store.commit("SET_LOADING", true);
      return PropertyUnitsService.all(this.property).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    groupSelected(e) {
      this.formData.group_id = e.target.value;
    },
    getPropertyUnitSize() {
      return PropertyUnitSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.property_unit_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      console.log(this.formData);
      this.formData.visible = !this.formData.visible;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.price.$touch();
      this.v$.formData.group_id.$touch();
      this.v$.formData.buy_price.$touch();
      this.v$.formData.stock_amount.$touch();
      this.v$.formData.reorder_level.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      if (this.formData.propertys.length == 0) {
        this.show_location_error = true;
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("propertys", this.formData.propertys);
      formData.append("name", this.formData.name);
      formData.append("reorder_level", this.formData.reorder_level);
      formData.append("price", this.formData.price);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("group", this.formData.group);
      formData.append("stock_amount", this.formData.stock_amount);
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      PropertyUnitsService.store(formData, config).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            this.page = 1;
            this.current_page = 1;
            this.getAllPropertyUnits(this.page);
            toast.success(response.data.message, {
              autoClose: 2000,
            });
          } else {
            console.log(response);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.price.$touch();
      this.v$.formData.group_id.$touch();
      this.v$.formData.buy_price.$touch();
      this.v$.formData.stock_amount.$touch();
      this.v$.formData.reorder_level.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      if (this.formData.propertys.length == 0) {
        this.show_location_error = true;
        return;
      }
      this.$store.commit("SET_LOADING", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("propertys", this.formData.propertys);
      formData.append("id", this.formData.id);
      formData.append("name", this.formData.name);
      formData.append("reorder_level", this.formData.reorder_level);
      formData.append("price", this.formData.price);
      formData.append("barcode", this.formData.barcode);
      formData.append("group_id", this.formData.group_id);
      formData.append("buy_price", this.formData.buy_price);
      formData.append("unit_name", this.formData.unit_name);
      formData.append("group", this.formData.group);
      formData.append("stock_amount", this.formData.stock_amount);
      // custom for uploads
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      PropertyUnitsService.update2(formData, config).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getAllPropertyUnits();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteResource(resource) {
      console.log(resource);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        PropertyUnitsService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
              this.getAllPropertyUnits();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return PropertyUnitsService.index().then(
        (response) => {
          console.log("getdata");
          console.group(response);
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
