// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class CompanyService {

    getAll() {
        return axios.get(VUE_APP_BASE_URL + 'company', { headers: authHeader() })
    }
    store(user) {
        return axios.post(VUE_APP_BASE_URL + 'company', user, { headers: authHeader() })
    }
    update(user) {
        return axios.put(VUE_APP_BASE_URL + 'company/'+user.id, user, { headers: authHeader() })
    }
    delete(user) {
        return axios.delete(VUE_APP_BASE_URL + 'company/'+user.id, { headers: authHeader() })
    }
    setBalances() {
        return axios.get(VUE_APP_BASE_URL + 'set-balances', { headers: authHeader() })
    }
}

export default new CompanyService()