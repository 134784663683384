<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card bg-100 shadow-none border">
                    <div class="row gx-0 flex-between-center">
                        <div class="col-sm-auto d-flex align-items-center">
                            <img class="ms-n2" src="assets/img/illustrations/crm-bar-chart.png" alt="" width="90" />

                            <div>
                                <h6 class="text-primary fs--1 mb-0">
                                    {{ getTimegreetings() }}
                                </h6>

                                <h4 class="text-primary fw-bold mb-0">
                                    <span class="text-info fw-medium">{{ user.surname }}</span>
                                    {{ user.other_names }}
                                </h4>
                            </div>

                            <img class="ms-n4 d-md-none d-lg-block" src="assets/img/illustrations/crm-line-chart.png" alt=""
                                width="150" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row g-2 ps-0">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card mb-2">
                    <div class="p-0" v-if="user && open_orders_count">
                        <ul class="mb-0 list-unstyled">
                            <li class="alert mb-0 rounded-0 py-2 px-card alert-info border-x-0 border-top-0">
                                <div class="row flex-between-center">
                                    <div class="col">
                                        <div class="d-flex">
                                            <div class="fas fa-circle mt-1 fs--2"></div>
                                            <p class="fs--1 ps-2 mb-0">
                                                <strong>{{ open_orders_count }} order(s)</strong> are
                                                waiting your action!
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-auto d-flex align-items-center">
                                        <a class="alert-link fs--1 fw-medium" @click="goToAllOrders" role="button">View
                                            orders<i class="fas fa-chevron-right ms-1 fs--2"></i></a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Edn goups  -->

                <div class="card">
                    <div class="card-header">
                        <div class="row flex-between-center border-bottom">
                            <div class="col-4 col-sm-auto align-items-center pe-0">
                                <h5 v-if="property_selection" class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                                    <i class="fa fa-shopping-cart text-warning"></i> Properties
                                </h5>
                                <h5 v-else class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                                    <i class="fa fa-shopping-cart text-warning"></i> Receive payments
                                </h5>

                                <p v-if="property_selection" class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                                    Search items by name or barcode
                                </p>
                                <p v-else class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                                    All available properties
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="card-body pt-0">
                        <div class="row">
                            <div v-if="!property_selection" class="row mb-2">
                                <!-- <div class="col-1 d-flex">
                                    <h5 class="mt-2"> Filter</h5>
                                </div>
                                <div class="col-2 d-flex">
                                    <select @change="getMonths" class="form-select" id="recipient-name" type="text"
                                        v-model="formData.year">
                                        <option v-for="year in years" :key="year" :value="year"
                                            :selected="year == formData.year">{{ year }}</option>
                                    </select>
                                </div>
                                <div class="col-2 d-flex">
                                    <select class="form-select" id="recipient-name" type="text" v-model="formData.month">
                                        <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
                                    </select>
                                </div> -->
                                <div class="col-8 col-sm-auto ms-auto text-end ps-0 d-flex mb-1">
                                    <button class="btn btn-sm me-2"
                                        :class="list_view ? 'btn-falcon-warning' : 'btn-warning'" @click="deselectProperty">
                                        <i class="fa fa-arrow-left"></i>
                                    </button>
                                    <button class="btn btn-sm me-2"
                                        :class="list_view ? 'btn-falcon-warning' : 'btn-warning'" @click="toggleView">
                                        <i class="fa fa-table"></i>
                                    </button>

                                    <div class="input-group shadow">
                                        <input v-if="!property_selection" ref="search_input"
                                            class="form-control search ms-auto fw-bold fs-bold border" type="search"
                                            placeholder="Search unit..." aria-label="search" width="100%"
                                            v-model="search_term" v-on:keyup.enter="searchItemOrBarcode" />
                                        <div v-if="!property_selection" class="input-group-text bg-transparent">
                                            <span class="fa fa-search fs--1 text-600"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div v-if="!property_selection">
                                    <div v-if="list_view" class="row">
                                        <div v-if="items.length > 0" class="table-responsive scrollbar-overlay"
                                            style="max-height: 30rem">
                                            <table class="table table-bordered table-striped">
                                                <thead class="bg-light fw-bold text-primary text-900">
                                                    <tr>
                                                        <th width="30%">
                                                            Unit
                                                        </th>
                                                        <th width="30%">
                                                            Tenant
                                                        </th>
                                                        <th>
                                                            Action
                                                        </th>
                                                        <th>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="">
                                                    <tr v-for="(item, i) in items" :key="i" @click="editItem(item)"
                                                        role="button">
                                                        <td>
                                                            {{ item.name }}
                                                        </td>
                                                        <td>
                                                            <p v-if="item.tenant_id == null" class="text-warning">Not leased
                                                            </p>
                                                            <strong v-else>{{ item.tenant?.surname }} {{
                                                                item.tenant?.other_names }}</strong>
                                                        </td>
                                                        <td>
                                                            <button v-if="item.tenant_id == null"
                                                                class="btn btn-sm btn-secondary" @click="goToLease()">
                                                                Vacant
                                                            </button>
                                                            <button v-if="item.tenant_id != null"
                                                                class="btn me-1 btn-sm btn-success" data-bs-toggle="modal"
                                                                data-bs-target="#pay-modal" @click="editItem(item)">
                                                                Other Payment
                                                            </button>
                                                            <button v-if="item.tenant_id != null"
                                                                class="btn me-1 btn-sm btn-warning" data-bs-toggle="modal"
                                                                data-bs-target="#attach-modal" @click="editItem(item)">
                                                                <i class="fa fa-tag"></i> Attach Charge
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {{ formatToCurrency(item.arrears) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <p v-else>No item found!</p>
                                    </div>

                                    <div v-if="!list_view" class="row mx-1">
                                        <div v-if="items.length > 0"
                                            class="col-6 col-md-4 col-lg-4 border rounded-1 d-flex flex-column justify-content-between scrollbar-overlay"
                                            style="max-height: 30rem" v-for="(item, i) in items" :key="i"
                                            @click="addTocart(item)">
                                            <span v-if="user.company.show_item_buying_price"
                                                class="btn btn-sm btn-falcon-secondary mt-1">B.P {{
                                                    item.buy_price }}</span>

                                            <div class="overflow-hidden">
                                                <div class="position-relative rounded-top overflow-hidden">
                                                    <a class="d-block text-center" role="button">
                                                        <img v-if="item.image" class="rounded-3 mt-1 img-flui"
                                                            :src="item.image" alt="Product Image" height="60" />

                                                        <img v-else class="rounded-3 mt-3 img-flui"
                                                            src="assets/img/no-image.jpg" alt="Product Image" height="60" />
                                                    </a>
                                                </div>

                                                <div class="text-center mt-2">
                                                    <span v-if="user.company.show_item_quantity" class="badge rounded-pill"
                                                        :class="item.stock_amount > item.reorder_level
                                                            ? 'badge-soft-info'
                                                            : 'badge-soft-danger'
                                                            ">Qty: {{ item.stock_amount }}</span>

                                                    <h5 class="fs--1">
                                                        <a class="text-dark fw-bold" role="button">
                                                            {{ truncateText(item.name, 15).toUpperCase() }}
                                                        </a>
                                                    </h5>

                                                    <span class="fs--3 text-warning d-block" role="button">
                                                        <small class="me-1">KES</small>
                                                        <b>{{ formatToCurrency(item.price) }}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <p v-else>No item found!</p>
                                    </div>

                                    <div class="d-fle justify-content-left mt-3">
                                        <pagination v-model="current_page" :records="records" :per-page="per_page"
                                            @paginate="myCallback" />
                                    </div>
                                </div>
                                <!-- Order type selection  -->
                                <div v-if="property_selection" class="row g-2">
                                    <div v-if="propertys.length > 0"
                                        class="btn btn-falcon-warning col-4 col-md-3 col-lg-3 border rounded-2 d-flex flex-column justify-content-between pt-3"
                                        v-for="(item, i) in propertys" :key="i" @click="selectProperty(item)">
                                        <div class="overflow-hidden">
                                            <div class="position-relative rounded-top overflow-hidden">
                                                <p class="d-block text-center" role="button">
                                                    <img v-if="item.icon" :src="item.icon" class="fa-3x" height="100px"
                                                        width="100px" />
                                                    <i v-else class="fa fa-table fa-3x"></i>
                                                </p>
                                            </div>
                                            <div class="text-center mt-2">
                                                <h5 class="fs-1">
                                                    <p class="text-primary fw-bold" role="button">
                                                        {{ truncateText(item.name, 15).toUpperCase() }}
                                                    </p>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <p v-else>No item found!</p>

                                    <img :src="user.company.logo" />
                                </div>
                            </div>
                            <!-- Cart  -->
                        </div>
                    </div>

                    <div class="card-footer bg-light d-flex justify-content-center">
                        <!-- <div>Bulla</div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="pay-modal" tabindex="-1" role="dialog" aria-labelledby="authentication-modal-label"
            aria-hidden="true">
            <div class="modal-dialog mt-6" role="document">
                <div class="modal-content border-0">
                    <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
                        <div class="position-relative z-index-1 light">
                            <h4 class="mb-0 text-white" id="authentication-modal-label">
                                Add Payment
                            </h4>
                            <p class="fs--1 mb-0 text-white">
                                Please create your payment
                            </p>
                        </div>

                        <button class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body py-4 px-5">
                        <div class="mb-3">
                            <label class="form-label" for="modal-auth-name">Property Name</label>
                            <input class="form-control" type="text" autocomplete="on" id="modal-auth-name" disabled
                                v-model="formData.property_name" />
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="modal-auth-name">Unit Name</label>
                            <input class="form-control" type="text" autocomplete="on" id="modal-auth-name" disabled
                                v-model="formData.unit_name" />
                        </div>
                        <div class="row gx-2">
                            <div class="mb-3 col-sm-6">
                                <label class="form-label" for="modal-auth-password">Type</label>

                                <select @change="checkOption" class="form-select" id="recipient-name" type="text"
                                    v-model="formData.payment_type">
                                    <option :selected="formData.payment_type == 'Rent'" value="Rent">Rent</option>
                                    <option :selected="formData.payment_type == 'Arrears'" value="Arrears">Arrears</option>
                                    <option :selected="formData.payment_type == 'Deposit'" value="Deposit">Deposit</option>
                                    <option :selected="formData.payment_type == 'Late Penalty'" value="Late Penalty">Late
                                        Penalty
                                    </option>
                                    <option :selected="formData.payment_type == 'Advance Payments'"
                                        value="Advance Payments">
                                        Advance Payments</option>
                                    <option :selected="formData.payment_type == 'Others'" value="Others">Others</option>
                                </select>

                                <label class="mt-3 form-label" for="modal-auth-password">Payment Mode</label>
                                <div class="form-check"><input class="form-check-input" id="flexRadioDefault2" type="radio"
                                        name="flexRadioMethod" checked="" @click="setPaymentMethod('Cash')" />
                                    <label class="form-check-label" for="flexRadioDefault2">Cash</label>
                                </div>
                                <div class="form-check"><input class="form-check-input" id="flexRadioDefault1" type="radio"
                                        name="flexRadioMethod" @click="setPaymentMethod('M-Pesa')" />
                                    <label class="form-check-label" for="flexRadioDefault1">M-Pesa</label>
                                </div>
                                <div class="form-check"><input class="form-check-input" id="flexRadioDefault1" type="radio"
                                        name="flexRadioMethod" @click="setPaymentMethod('Bank/Card')" />
                                    <label class="form-check-label" for="flexRadioDefault1">Bank/Card</label>
                                </div>
                            </div>
                            <div class="mb-3 col-sm-6">
                                <label class="form-label" for="modal-auth-password">Arrears</label>
                                <input class="mb-3 form-control" disabled v-model="formData.unit_arrears" type="text"
                                    autocomplete="on" id="modal-auth-password" />

                                <label class="form-label" for="modal-auth-confirm-password">Amount</label>
                                <input @change="adjustAmount" class="mb-3 form-control" type="number" autocomplete="on"
                                    id="modal-auth-confirm-password" v-model="formData.amount" />

                                <label class="form-label" for="modal-auth-confirm-password">Note</label>
                                <textarea class="form-control" type="text" autocomplete="on"
                                    id="modal-auth-confirm-password" v-model="formData.note" />
                            </div>
                        </div>
                        <div class="mb-3">
                            <button class="btn btn-primary d-block w-100 mt-3" @click="submitPayment">
                                Complete payment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="attach-modal" tabindex="-1" role="dialog" aria-labelledby="authentication-modal-label"
            aria-hidden="true">
            <div class="modal-dialog mt-6" role="document">
                <div class="modal-content border-0">
                    <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
                        <div class="position-relative z-index-1 light">
                            <h4 class="mb-0 text-white" id="authentication-modal-label">
                                Attach Charge
                            </h4>
                            <p class="fs--1 mb-0 text-white">
                                Please create your bill
                            </p>
                        </div>

                        <button class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body py-4 px-5">
                        <div class="mb-3">
                            <label class="form-label" for="modal-auth-name">Property Name</label>
                            <input class="form-control" type="text" autocomplete="on" id="modal-auth-name" disabled
                                v-model="formData.property_name" />
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="modal-auth-name">Unit Name</label>
                            <input class="form-control" type="text" autocomplete="on" id="modal-auth-name" disabled
                                v-model="formData.unit_name" />
                        </div>
                        <div class="row gx-2">
                            <div class="mb-3 col-sm-6">
                                <label class="form-label" for="modal-auth-password">Type</label>
                                <select class="mb-3 form-select" id="recipient-name" type="text" v-model="formData.type">
                                    <option :selected="formData.type == 'Rent'" value="Rent">Rent</option>
                                    <option :selected="formData.type == 'Deposit'" value="Deposit">Deposit</option>
                                    <option :selected="formData.type == 'Penalty'" value="Penalty">Penalty</option>
                                    <option :selected="formData.type == 'Service Charge'" value="Service Charge">
                                        Service Charge</option>
                                    <option :selected="formData.type == 'Others'" value="Others">Others</option>
                                </select>
                                <label class="form-label" for="modal-auth-confirm-password">Year</label>
                                <input class="mb-3 form-control" type="number" autocomplete="on"
                                    id="modal-auth-confirm-password" v-model="formData.year" />

                            </div>
                            <div class="mb-3 col-sm-6">
                                <label class="form-label" for="modal-auth-confirm-password">Amount</label>
                                <input class="mb-3 form-control" type="number" autocomplete="on"
                                    id="modal-auth-confirm-password" v-model="formData.amount" />

                                <label class="form-label" for="modal-auth-confirm-password">Month</label>
                                <input class="mb-3 form-control" type="number" autocomplete="on"
                                    id="modal-auth-confirm-password" v-model="formData.month" />
                            </div>
                            <div class="mb-3 col-sm-12">
                                <label class="form-label" for="modal-auth-confirm-password">Note</label>
                                <textarea class="form-control" type="text" autocomplete="on"
                                    id="modal-auth-confirm-password" v-model="formData.description" />
                            </div>
                        </div>
                        <div class="mb-3">
                            <button class="btn btn-primary d-block w-100 mt-3" @click="submitCharge">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.customize-table {
    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-background-color: #edf2f9;
    --easy-table-body-row-height: 60px;
    --easy-table-body-row-font-size: 12px;
}

.page-link {
    padding-left: 18px !important;
    padding-right: 18px !important;
}
</style>

<script>
import axios from "axios";
import $ from "jquery";
import CountUp from "vue-countup-v3";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Pagination from "v-pagination-3";
import CartService from "../services/cart.service";
import AuthService from "../services/auth.service";
import TablesService from "../services/tables.service";
import LeaseService from "../services/lease.service";
import ProductService from "../services/product.service";
import PropertyUnitService from "../services/property_unit.service";
import MenuGroupService from "../services/menu_group.service";
import PropertyTypeService from "../services/property_type.service";
import TenantService from "../services/tenant.service";
import ReportService from "../services/report.service";
import PropertyService from "../services/propertys.service";
import useVuelidate from "@vuelidate/core";
import OrderService from "../services/orders.service";
import { required } from "@vuelidate/validators";
import CompanyService from "../services/company.service";

export default {
    mixins: [mixins],
    name: "TenantsView",
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            formData: {
                phone: { required },
                price: { required },
            },
        };
    },
    components: {
        CountUp,
        Pagination,
    },
    data() {
        return {
            rent_amount: "",
            can_retry: true,
            retrying: false,
            errorMessage: false,
            successMessage: false,
            stk_sent: false,
            transaction_complete: false,
            tender_amt: "",
            cart_balance: "",
            print_receipt: true,
            open_orders_count: 0,
            search_term: "",
            list_view: true,
            per_page: 25,
            current_page: 0,
            records: 0,
            checkout: false,
            daily_analysis: [],
            weekly_analysis: [],
            formData: {
                year: "",
                tenant_id: "",
                tenant_name: "",
                property_id: "",
                property_name: "",
                payment_type: "Rent",
                payment_method: "Cash",
                transaction_type: "Payment"
            },
            searchKey: "",
            barcode: "",
            loading_qr: false,
            dialog: false,
            items: {},
            tables: [],
            propertys: [],
            years: [],
            months: [],
            dialogQty: false,
            itemDialog: false,
            productCategories: [],
            tenants: [],
            groups: [],
            selectedProductCategory: "",
            cart: {},
            table_selection: localStorage.getItem("table") == null ? true : false,
            category_selection: false,
            property_selection: true,
            property:
                localStorage.getItem("property") == null
                    ? {}
                    : JSON.parse(localStorage.getItem("property")),
            selectedCategory: 0,
            selected_category: "",
            selected_category_data: {},
            selected_group: "",
            selected_table: "",
            selected_tenant: "",
        };
    },
    mounted() {
        console.log("default_location" + JSON.stringify(this.propertys))
        if (this.default_location != 0) {
            // let Propertys = JSON.stringify(this.propertys)
            // let Property = Propertys.find((el)=> el.id==this.default_location);
            // this.selectProperty(Property);
            this.getProperty(this.default_location);
        }

    },
    async created() {
        let status = await AuthService.checkAuth();
        console.log("ERR STATUS" + status)
        switch (status) {
            case 401:
                localStorage.removeItem("user");
                alert("Session expired");
                this.$router.push("/login");
                break;
            case 423:
                alert("Account locked due to insufficient funds!");
                localStorage.removeItem("user");
                this.$router.push("/login");
                break;
            default:
                break;
        }
        await this.getAllPropertys();
        this.getAllCategories();
        this.getAllGroups();
        this.setBalances();
        this.getTenants();
        this.getYears();
        const date = new Date();
        this.formData.month = date.toLocaleString('en-us', { month: 'long' });
        this.formData.year = date.toLocaleString('en-us', { year: 'long' });
    },
    computed: {
        balance() {
            if (this.tender_amt) {
                return this.tender_amt - this.totalValue;
            } else {
                return "";
            }
        },
        totalValue() {
            let itemArray = Object.values(this.cart);
            let totalValue = 0;
            itemArray.forEach((element) => {
                totalValue +=
                    parseFloat(element.quantity) *
                    parseFloat(
                        this.formData.retail_price ? element.price : element.ws_price
                    );
            });
            return parseFloat(totalValue);
        },
        totalVat() {
            let itemArray = Object.values(this.cart);
            let totalValue = 0;
            itemArray.forEach((element) => {
                if (element.vat) {
                    totalValue +=
                        parseFloat(element.quantity) *
                        parseFloat(
                            this.formData.retail_price
                                ? this.getVAT(element.price)
                                : this.getVAT(element.ws_price)
                        );
                }
            });
            return totalValue;
        },
        cartLength() {
            let data = this.cart;
            if (data.length) {
                return data.length;
            } else {
                return "empty";
            }
        },
        cartItems() {
            let cartData = Object.values(this.cart);
            return cartData;
        },
        table() {
            return localStorage.getItem("table") == null ? {} : this.selected_table;
        },
        category() {
            return this.selected_category_data;
        },
        company() {
            return JSON.parse(localStorage.getItem("user")).company;
        },
        user() {
            return JSON.parse(localStorage.getItem("user"));
        },
        allow_price_edits() {
            let user = JSON.parse(localStorage.getItem("user"));
            return user ? user.company.allow_price_edits : false;
        },
        default_location() {
            let user = JSON.parse(localStorage.getItem("user"));
            return user.company.default_location;
        }
    },
    methods: {
        clearModals() {
            $("#pay-modal").removeClass("fade").hide();
            $("#edit-modal").removeClass("fade").hide();
            $("#attach-modal").removeClass("fade").hide();
            $(".modal-backdrop").remove();
            this.formData = {};
        },
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        adjustAmount() {
            if (this.formData.payment_type == 'Deposit') {
                if (this.formData.amount > this.formData.deposit) {
                    this.formData.amount = this.formData.deposit
                    toast.info("Amount cannot be more that he deposit!", {
                        autoClose: 2000,
                    });
                } else {
                    this.formData.amount = this.formData.deposit
                }
            } else if (this.formData.payment_type == 'Rent') {
                if (this.formData.amount > this.formData.rent) {
                    toast.info("Amount cannot be more than the rent!", {
                        autoClose: 2000,
                    });
                    this.formData.amount = this.formData.rent
                } else {
                    this.formData.amount = this.formData.rent
                }

            }
        },
        checkOption() {
            if (this.formData.payment_type == 'Deposit') {
                console.log(this.formData)
                this.formData.amount = this.formData.deposit
            } else if (this.formData.payment_type == 'Rent') {
                this.formData.amount = this.formData.rent
            }
        },
        setBalances() {
            CompanyService.setBalances().then(
                (response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        toast.success(response.data.message, {
                            autoClose: 2000,
                        });
                        this.getData();
                        this.clearModals();
                    } else {
                        this.resources = [];
                        console.log(response.data.error);
                        toast.error(response.data.error, {
                            autoClose: 2000,
                        });
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        async addBulkPayment(e, item) {
            if (confirm("Are you sure you want to receive a payment of " + e.target.value + "?")) {
                this.formData.balance = this.formData.unit_arrears - this.formData.amount;
                this.formData.amount = e.target.value;
                // reset form
                this.formData.note = "";
                this.formData.tenant_id = "";
                this.formData.tenant_name = "";
                this.formData.property_id = "";
                this.formData.property_name = "";
                this.formData.payment_type = "Rent";
                this.formData.transaction_type = "Payment";
                // set items
                this.formData.unit_id = item.id;
                this.formData.unit_name = item.name;
                this.formData.deposit = item.deposit;
                this.formData.tenant_id = item.tenant.id;
                this.formData.tenant_name = item.tenant.surname + ' ' + item.tenant.other_names,
                    this.formData.unit_arrears = item.arrears;
                this.formData.property_name = item.property.name;
                this.formData.property_id = item.property.id;
                this.formData.user_id = this.user.id;
                this.formData.created_by = this.user.surname;
                await this.submitPayment();
            }


        },
        setPaymentType(type) {
            this.formData.payment_type = type;
        },
        setPaymentMethod(method) {
            this.formData.payment_method = method;
        },
        goToLease() {
            this.$router.push("/new-lease");
        },
        resetCalc() {
            this.tender_amt = "";
            this.balance = "";
        },
        printReceipt() {
            OrderService.printReceiptSuper(this.formData).then(
                (response) => {
                    toast.success("Receipt printed!", {
                        autoClose: 2000,
                    });
                    console.log(response);
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    toast.error(
                        error.response.data.message ||
                        (error.response && error.response.data) ||
                        error.mesage ||
                        error.toString(),
                        { autoClose: 3000 }
                    );
                    console.log(error);
                }
            );
        },
        beepSound() {
            var audio = new Audio("/error_sound.wav"); // path to file
            audio.play();
        },
        editItemQty(item, evt) {
            this.editItem(item, evt.target.value);
        },
        mySelectEvent({ id, text }) {
            console.log({ id, text });
            this.formData.tenant_id = id;
            this.formData.tenant_name = text;
        },
        editItemPrice(e, item) {
            console.log("price", e.target.value);
            console.log("item", item);
            let newPrice = e.target.value;
            this.updateSingleItemPrice(item, newPrice);
        },
        updateSingleItemPrice(item, newPrice) {
            let myCart = this.cart ? Object.values(this.cart) : [];
            let existingElement = myCart.find((element) => element.id == item.id);
            item.sub_total = newPrice;
            existingElement.price = newPrice / item.quantity;
            existingElement.sub_total = newPrice;
            this.cart = myCart;
            this.$store.dispatch("pos/updateCart", this.cart);
        },

        toggleRetail() {
            this.formData.retail_price = !this.formData.retail_price;
        },
        toggleView() {
            this.list_view = !this.list_view;
        },
        selectGroup(e) {
            this.selected_group = e.id;
            this.formData.group_name = e.name;
            let formData = {
                menu_group_id: e.id,
            };
            this.menuList(formData, 1);
        },
        async searchItemOrBarcode() {
            if (!isNaN(this.search_term)) {
                console.log("searchItemByBarcode");
                await this.searchItemByBarcode();
                this.$store.commit("SET_LOADING", false);
            } else {
                await this.searchItem();
                this.$store.commit("SET_LOADING", false);
            }
        },
        searchItemByBarcode() {
            this.$store.commit("SET_LOADING", true);
            let formData = {
                search_term: this.search_term,
            };
            return PropertyUnitService.menuSearchByCode(formData).then(
                (response) => {
                    if (response.data.code == 200) {
                        let item = response.data.data;
                        // add to cart by barcode
                        this.addTocart(item);
                        this.$store.commit("SET_LOADING", false);
                    } else {
                        console.log(response.data.error);
                        this.$store.commit("SET_LOADING", false);
                    }
                    this.search_term = "";
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    this.beepSound();
                    toast.error("Item not found, please search!");
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.search_term = "";
                }
            );
        },
        searchItem() {
            this.$store.commit("SET_LOADING", true);
            this.page = 1;
            this.per_page = 25;
            this.current_page = 0;
            let formData = {
                search_term: this.search_term,
            };
            return PropertyUnitService.menuSearch(formData, this.current_page).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.items = response.data.data.data;
                        this.records = response.data.data.total;
                    } else {
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        deleteItem(item) {
            this.cart.splice(this.cart.indexOf(item), 1);
        },
        attachCharge(item) {
            this.formData.amount = "";
            this.formData.note = "";
            this.formData.tenant_id = "";
            this.formData.tenant_name = "";
            this.formData.property_id = "";
            this.formData.property_name = "";
            this.formData.payment_type = "Rent";
            this.formData.transaction_type = "Payment";
            // set items
            this.formData.unit_id = item.id;
            this.formData.unit_name = item.name;
            this.formData.tenant_id = item.tenant.id;
            this.formData.deposit = item.deposit;
            this.formData.tenant_name = item.tenant.surname + ' ' + item.tenant.other_names,
                this.formData.unit_arrears = item.arrears;
            this.formData.property_name = item.property.name;
            this.formData.property_id = item.property.id;
            this.formData.user_id = this.user.id;
            this.formData.created_by = this.user.surname;
        },
        editItem(item) {
            console.log(item);
            // reset form
            this.formData.amount = "";
            this.formData.note = "";
            this.formData.tenant_id = "";
            this.formData.tenant_name = "";
            this.formData.property_id = "";
            this.formData.property_name = "";
            this.formData.payment_type = "Rent";
            this.formData.transaction_type = "Payment";
            // set items
            this.formData.unit_id = item.id;
            this.formData.unit_name = item.name;
            this.formData.tenant_id = item.tenant.id;
            this.formData.deposit = item.deposit;
            this.formData.rent = item.neg_price;
            this.formData.tenant_name = item.tenant.surname + ' ' + item.tenant.other_names,
                this.formData.unit_arrears = item.arrears;
            this.formData.property_name = item.property.name;
            this.formData.property_id = item.property.id;
            this.formData.user_id = this.user.id;
            this.formData.created_by = this.user.surname;
            this.checkOption();
        },
        truncateText(str, n) {
            return str.length > n ? str.substr(0, n - 1) + "..." : str;
        },
        toggleCheckout(val) {
            this.checkout = val;
        },
        formatToCurrency: function (amount) {
            return parseInt(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
        },
        sortItems(list) {
            return list.sort((a, b) => (a.name > b.name ? 1 : -1));
        },
        getTimegreetings() {
            const time = new Date().getHours();
            let greetings;
            if (time < 12) greetings = "Good Morning";
            else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
            else greetings = "Good Evening";
            return greetings;
        },
        tenderedInput() {
            this.formData.balance_amount =
                parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
        },
        async categorySelected(cat) {
            this.selected_category_data = cat;
            this.selected_category = cat.id;
            this.formData.category_name = cat.name;
            this.formData.category = cat.id;
            this.getMenuGroupsByCategory(cat.id);
        },
        getMenuGroupsByCategory(selected_category) {
            let formData = {
                property_type: selected_category,
            };
            return ProductService.getMenuGroupsByCategory(formData).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.groups = response.data.data;
                        if (response.data.data.length > 0) {
                            let formData = {
                                menu_group_id: this.groups.at(0).id,
                            };
                            this.menuList(formData, 1);
                        }
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },

        orderLease() {
            if (this.cartLength == "empty") {
                toast.error("No item selected, please add items to cart!");
                return;
            }
            this.$store.commit("SET_LOADING", true);
            this.formData.cart = this.cart;
            this.formData.table_id = this.selected_table.id;
            this.formData.total_amount = this.totalValue;
            this.formData.property_id = this.property.id;
            this.formData.property_name = this.property.name;
            LeaseService.order(this.formData).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.$store.commit("SET_LOADING", false);
                        toast.success("Order placed!");
                        this.getNotifications();
                        this.resetCalc();
                        this.cart = {};
                    } else {
                        console.log(response.data.error);
                        toast.error("Error placing order!");
                        this.$store.commit("SET_LOADING", false);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
            this.open_orders_count += 1;
        },
        clearCart() {
            this.cart = {};
            toast.info("Cart cleared!");
        },
        goToMyOrders() {
            this.$router.push("/my-orders");
        },
        goToAllOrders() {
            this.$router.push("/pos-open-order");
        },
        handleLogout() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
        menuList(formData, page) {
            this.$store.commit("SET_LOADING", true);
            return PropertyUnitService.menuList(formData, page).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.items = response.data.data.data;
                        this.records = response.data.data.total;
                        this.current_page = response.data.data.current_page;
                    } else {
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getItemsByOrder(item) {
            this.$store.commit("SET_LOADING", true);
            return CartService.byOrder(item.id).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.items = response.data.data.data;
                        this.records = response.data.data.total;
                        this.current_page = response.data.data.current_page;
                    } else {
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        myCallback(e) {
            let formData = {};
            if (this.selected_group) {
                formData.menu_group_id = this.selected_group;
            }
            if (this.property.id) {
                formData.property_id = this.property.id;
            }
            if (this.search_term) {
                this.searchItem();
            } else {
                this.menuList(formData, e);
            }
        },
        getAll() {
            return CartService.getAll().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.items = response.data.data.data;
                        this.current_page = response.data.data.current_page;
                        this.$store.commit("SET_LOADING", false);
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getAllTables() {
            this.$store.commit("SET_LOADING", true);
            return TablesService.index().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.tables = response.data.data;
                        if (this.tables.length > 0) this.selected_table = this.tables.at(0);
                        this.$store.commit("SET_LOADING", falses);
                    } else {
                        this.$store.commit("SET_LOADING", false);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        getAllGroups() {
            this.$store.commit("SET_LOADING", true);
            return MenuGroupService.index().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.groups = response.data.data;
                        this.formData.group_name = response.data.data.at(0).name;
                    } else {
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        getAllCategories() {
            this.$store.commit("SET_LOADING", true);
            return PropertyTypeService.index().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.productCategories = response.data.data;
                        this.formData.category = response.data.data.at(0).id;
                        this.formData.category_name = response.data.data.at(0).name;
                    } else {
                        console.log(response.data.error);
                    }
                    this.$store.commit("SET_LOADING", false);
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        getAllPropertys() {
            return PropertyService.index().then(
                (response) => {
                    if (response.data.code == 200) {
                        this.propertys = response.data.data;
                        this.loading = false;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getProperty(id) {
            return PropertyService.show(id).then(
                (response) => {
                    if (response.data.code == 200) {
                        let item = response.data.data;
                        this.selectProperty(item)
                        this.loading = false;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getMenuGroupsByOrder() {
            let formData = {
                property: this.property.id,
            };
            return ProductService.getMenuGroupsByOrder(formData).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.groups = response.data.data;
                        this.selected_group = response.data.data.at(0).id;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getYears() {
            return ReportService.getYears().then(
                (response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        this.years = response.data.data;
                        this.formData.year = response.data.data.at(0);
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    toast.error(
                        error.response.data.message ||
                        (error.response && error.response.data) ||
                        error.mesage ||
                        error.toString(),
                        { autoClose: 3000 }
                    );
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getMonths() {
            return ReportService.getMonths(this.formData.year).then(
                (response) => {
                    console.log(response);
                    if (response.data.code == 200) {
                        this.months = response.data.data;
                        this.formData.month = response.data.data.at(0);
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    toast.error(
                        error.response.data.message ||
                        (error.response && error.response.data) ||
                        error.mesage ||
                        error.toString(),
                        { autoClose: 3000 }
                    );
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getTenants() {
            return TenantService.index().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.formData.tenant_id = response.data.data.at(0).id;
                        this.tenants = response.data.data.map((el) => {
                            return {
                                id: el.id,
                                text: el.surname + ' ' + el.other_names + ' ' + el.mobile_no,
                            };
                        });
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getAllProducts() {
            return ProductService.getAll().then(
                (response) => {
                    if (response.data.status == "success") {
                        this.items = response.data.data;
                    } else {
                        console.log(response.data.error);
                    }
                },
                (error) => {
                    console.log(error);
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                }
            );
        },
        getPropertyTypeByOrder(property) {
            if (this.propertys.length > 0) {
                this.formData.property = property;
                return ProductService.getPropertyTypeByOrder(this.formData).then(
                    (response) => {
                        if (response.data.code == 200) {
                            this.productCategories = response.data.data;
                        } else {
                            console.log(response.data.error);
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.$store.commit("SET_LOADING", false);
                        if (error.response.status == 401) {
                            this.handleLogout();
                        }
                    }
                );
            } else {
                this.productCategories = [];
            }
        },
        submitCharge() {
            if (this.formData.amount <= 0) {
                prompt('Enter the amount you want to pay?');
            }
            this.$store.commit("SET_LOADING", true);
            LeaseService.charge(this.formData).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.$store.commit("SET_LOADING", false);
                        toast.success("Charge saved!");
                        this.clearModals();
                        // window.location.reload();
                        this.selectProperty2();
                    } else {
                        console.log(response.data.error);
                        toast.error("Error placing order!");
                        this.$store.commit("SET_LOADING", false);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );

        },
        submitPayment() {
            if (this.formData.amount <= 0) {
                prompt('Enter the amount you want to pay?');
            }
            this.formData.balance = this.formData.unit_arrears - this.formData.amount;
            LeaseService.order(this.formData).then(
                (response) => {
                    if (response.data.code == 200) {
                        this.$store.commit("SET_LOADING", false);
                        toast.success("Payment received!");
                        this.clearModals();
                        // window.location.reload();
                        this.selectProperty2();
                    } else {
                        console.log(response.data.error);
                        toast.error("Error placing order!");
                        this.$store.commit("SET_LOADING", false);
                    }
                },
                (error) => {
                    this.$store.commit("SET_LOADING", false);
                    if (error.response.status == 401) {
                        this.handleLogout();
                    }
                    this.$store.commit("SET_LOADING", false);
                }
            );
        },
        selectTable(item) {
            this.table_selection = false;
            localStorage.setItem("table", JSON.stringify(item));
            this.$notify({
                group: "foo",
                type: "info",
                title: "Selection",
                text: "Table selected!",
            });
        },
        deselectProperty() {
            this.table_selection = false;
            this.property_selection = true;
            this.$refs.search_input.focus();
        },
        selectProperty(item) {
            this.property = item;
            this.table_selection = true;
            this.property_selection = false;
            localStorage.setItem("property", JSON.stringify(item));
            let formData = {
                property_id: item.id,
            };
            this.menuList(formData, 1);
            this.getPropertyTypeByOrder(item.id);
        },
        selectProperty2() {
            let formData = {
                property_id: this.property.id,
            };
            this.menuList(formData, 1);
            this.getPropertyTypeByOrder(this.property.id);
        },
    },
};
</script>
