<template>
  <div>
    <div class="card mb-3" id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-sm-4 col-xs-4 align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-home text-warning"></i>
              Property Units
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage property units
            </p>
          </div>
          <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-end ps-0">
            <div id="orders-actions" class="d-flex">
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable v-if="theme == 'dark'" id="tour-step-1" table-class-name="dark-table" alternating
          buttons-pagination :headers="headers" :items="resources" :search-field="searchField" :search-value="searchValue"
          :loading="loading">
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name.toUpperCase() }}</strong>
              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.created_at) }}
            </div>
          </template>

          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" id="order-dropdown-0"
                data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                <div class="py-2">
                  <a @click="editItem(item)" class="dropdown-item" role="button" data-bs-toggle="modal"
                    data-bs-target="#edit-modal">Edit</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item text-danger" role="button" @click="deleteResource(item)">Delete</a>
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <EasyDataTable v-else id="tour-step-1" table-class-name="customize-table" alternating buttons-pagination
          :headers="headers" :items="resources" :search-field="searchField" :search-value="searchValue"
          :loading="loading">
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name.toUpperCase() }}</strong>
              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.created_at) }}
            </div>
          </template>

          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" id="order-dropdown-0"
                data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">
                <div class="py-2">
                  <a @click="editItem(item)" class="dropdown-item" role="button" data-bs-toggle="modal"
                    data-bs-target="#edit-modal">Edit</a>

                  <div class="dropdown-divider"></div>
                  <a v-if="user.permissions.includes('delete-property-unit')" class="dropdown-item text-danger" role="button" @click="deleteResource(item)">Delete</a>
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start edit modal  -->
    <div>
      <div class="modal fade" id="edit-modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="edit-modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Edit Property</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-12">
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Name</label>
                          <input class="form-control" id="recipient-name" type="text" v-model="formData.name" :class="v$.formData.name.$dirty &&
                            v$.formData.name.$invalid
                            ? `is-invalid`
                            : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                            " />
                          <div v-for="error of v$.formData.name.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="message-text">Select type</label>
                          <select class="form-select d-inline" aria-label="Select type" v-model="formData.type" :class="v$.formData.type.$dirty &&
                            v$.formData.type.$invalid
                            ? `is-invalid`
                            : v$.formData.type.$dirty
                              ? `is-valid`
                              : ``
                            ">
                            <option value="Residential">Residential</option>
                            <option value="Commercial">Commercial</option>
                          </select>
                          <div v-for="error of v$.formData.type.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-1">
                      <div class="row">
                        <label class="col-form-label" for="message-text">Address</label>
                        <p class="text-word-break fs--1">
                          <textarea v-model="formData.address" class="form-control" id="exampleFormControlTextarea1"
                            rows="3" :class="v$.formData.address.$dirty &&
                              v$.formData.address.$invalid
                              ? `is-invalid`
                              : v$.formData.address.$dirty
                                ? `is-valid`
                                : ``
                              "></textarea>
                        <div v-for="error of v$.formData.address.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="border-top">
                    <!-- Units  -->
                    <!-- Start add form  -->
                    <div v-if="add_unit" class="border p-2 mb-2">
                      <div class="row">
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Unit Name</label>
                          <input class="form-control" id="recipient-name" type="text" v-model="unitData.name" :class="v$.formData.name.$dirty &&
                            v$.unitData.name.$invalid
                            ? `is-invalid`
                            : v$.unitData.name.$dirty
                              ? `is-valid`
                              : ``
                            " />
                          <div v-for="error of v$.unitData.name.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Rent Amount</label>
                          <input class="form-control" id="recipient-name" type="text" v-model="unitData.price" :class="v$.unitData.price.$dirty &&
                            v$.unitData.price.$invalid
                            ? `is-invalid`
                            : v$.unitData.price.$dirty
                              ? `is-valid`
                              : ``
                            " />
                          <div v-for="error of v$.unitData.price.$errors" :key="error.$uid" class="invalid-feedback"
                            style="color: red">
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Token No</label>
                          <input class="form-control" id="recipient-name" type="text" v-model="unitData.token_no" />
                        </div>
                        <div class="mb-3 col-6">
                          <label class="col-form-label" for="recipient-name">Meter No</label>
                          <input class="form-control" id="recipient-name" type="text" v-model="unitData.meter_no" />
                        </div>
                        <div class="mb-3 col-12">
                          <label class="col-form-label" for="recipient-name">Details</label>
                          <textarea class="form-control" id="recipient-name" type="text" v-model="unitData.description" />
                        </div>
                      </div>
                    </div>
                    <!-- End add form  -->
                    <button v-if="add_unit" class="btn btn-sm btn-rounded btn-falcon-success ms-auto my-2 ml-auto d-flex"
                      type="button" @click="saveUnit" data-placement="top" title="Add new unit">
                      Save
                    </button>
                    <button v-else class="btn btn-sm btn-rounded btn-falcon-warning ms-auto my-2 ml-auto d-flex"
                      type="button" @click="addNewUnit" data-placement="top" title="Add new unit">
                      Add
                    </button>

                    <span class="d-flex fw-bold">Property Units</span>
                    <table class="table table-bordered table-striped">
                      <thead class="bg-light fw-bold text-primary text-900">
                        <tr>
                          <th class="sort" data-sort="name" width="40%">
                            Unit Name
                          </th>
                          <th class="sort" data-sort="name" width="30%">
                            Tenant
                          </th>
                          <th class="sort text-end" data-sort="price" width="30%">
                            Rent Amt
                          </th>
                          <th class="sort text-end" data-sort="price" width="30%">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody class="">
                        <tr v-for="(item, i) in formData.units" :key="i" role="button">
                          <td>
                            <div class="my-0 fw-bol">
                              {{ item.name }}
                            </div>
                          </td>
                          <td class="text-start">
                            {{ item.tenant?.name ?? 'Not Leased' }}
                          </td>
                          <td class="text-end fw-bol">
                            {{ formatToCurrency(item.price) }}
                          </td>
                          <td class="text-end fw-bol">
                            <button class="btn btn-sm btn-rounded btn-falcon-danger d-flex" type="button"
                              @click="deleteUnit(item)" data-placement="top" title="Remove unit">
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button"
                @click="updateResource(resource)">
                <span class="fa fa-save me-2" data-fa-transform=""></span><span
                  class="d-none d-sm-inline-block ms-1">Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->
    <!-- Start add modal  -->
    <div class="modal fade" id="add-modal" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1"
      aria-labelledby="add-modalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add property</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-12">
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-6">
                        <label class="col-form-label" for="recipient-name">Name</label>
                        <input class="form-control" id="recipient-name" type="text" v-model="formData.name" :class="v$.formData.name.$dirty &&
                          v$.formData.name.$invalid
                          ? `is-invalid`
                          : v$.formData.name.$dirty
                            ? `is-valid`
                            : ``
                          " />
                        <div v-for="error of v$.formData.name.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-6">
                        <label class="col-form-label" for="message-text">Select type</label>

                        <select class="form-select d-inline" aria-label="Select type" v-model="formData.type" :class="v$.formData.type.$dirty &&
                          v$.formData.type.$invalid
                          ? `is-invalid`
                          : v$.formData.type.$dirty
                            ? `is-valid`
                            : ``
                          ">
                          <option value="Residential">Residential</option>
                          <option value="Commercial">Commercial</option>
                        </select>
                        <div v-for="error of v$.formData.type.$errors" :key="error.$uid" class="invalid-feedback"
                          style="color: red">
                          This field is invalid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="row">
                      <label class="col-form-label" for="message-text">Description</label>
                      <p class="text-word-break fs--1">
                        <textarea v-model="formData.address" class="form-control" id="exampleFormControlTextarea1"
                          rows="3" :class="v$.formData.address.$dirty &&
                            v$.formData.address.$invalid
                            ? `is-invalid`
                            : v$.formData.address.$dirty
                              ? `is-valid`
                              : ``
                            "></textarea>
                      <div v-for="error of v$.formData.address.$errors" :key="error.$uid" class="invalid-feedback"
                        style="color: red">
                        This field is invalid
                      </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block" type="button" @click="saveResource()">
              <span class="fa fa-save me-2" data-fa-transform=""></span><span
                class="d-none d-sm-inline-block ms-1">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuGroupService from "../services/menu_group.service";
import PropertyUnitSizeService from "../services/property_unit_size.service";
import PropertyUnitsService from "../services/property_unit.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "TenantsView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        type: { required },
        address: { required },
      },
      unitData: {
        name: { required },
        price: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      propertys: [],
      selected_category: "",
      property_unit_sizes: [],
      productCategories: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        group_id: "",
        active: 0,
        visible: 1,
        name: "",
      },
      add_unit: false,
      unitData: {
        name: "",
        price: "",
        group_id: "",
        property_id: "",
        description: "",
        token_no: "",
        meter_no: ""
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["id", "name", "price"],
      headers: [
        { text: "#", value: "id", sortable: true, widh: 10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Meter No", value: "meter_no", sortable: true },
        { text: "Token No", value: "token_no", sortable: true },
        { text: "Price", value: "price", sortable: true },
        { text: "Created", value: "created_at", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  async created() {
    // this.forceReload();
    this.getUnits();
  },
  watch: {},
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    formatToCurrency: function (amount) {
      return parseInt(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    addNew() {
      this.formData = {};
    },
    addNewUnit() {
      this.unitData = {};
      this.add_unit = true;
    },
    editItem(item) {
      console.log(item);
      this.formData = item;
    },
    async selectProperty(e) {
      this.$store.commit("SET_LOADING", true);
      await this.getItemsByOrder(e.target.value);
      this.$store.commit("SET_LOADING", false);
    },
    categorySelected(e) {
      console.log(e);
      this.getItemsByOrder(e.target.value);
    },
    sortItems(list) {
      return list.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;

            this.getItemsByOrder(response.data.data.at(0).id);
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItemsByOrder(item_id) {
      this.$store.commit("SET_LOADING", true);
      return CartService.byOrder(item_id).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = this.sortItems(response.data.data);
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    groupSelected(e) {
      this.formData.group_id = e.target.value;
    },
    getPropertyUnitSize() {
      return PropertyUnitSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.property_unit_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveUnit() {
      this.v$.unitData.name.$touch();
      this.v$.unitData.price.$touch();
      if (this.v$.unitData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.unitData.property_id = this.formData.id;
      PropertyUnitsService.store(this.unitData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getUnits();
            this.add_unit = false;
            console.log(response.data.data)
            this.formData.units.push(response.data.data)
          } else {
            toast.error(response.data.message || response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    saveResource() {
      this.v$.formData.name.$touch();
      this.v$.formData.type.$touch();
      this.v$.formData.address.$touch();
      console.log(this.v$.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      PropertyUnitsService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getUnits();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.v$.formData.name.$touch();
      this.v$.formData.type.$touch();
      this.v$.formData.address.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      PropertyUnitsService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getUnits();
            // Clear modals
            $("#edit-modal").removeClass("fade").hide();
            $(".modal-backdrop").remove();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteUnit(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        PropertyUnitsService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getUnits();
              // this.formData.units.filter((item => item.id != resource.id));
              window.location.reload();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    deleteResource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        PropertyUnitsService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getUnits();
              $("#edit-modal").removeClass("fade").hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getUnits() {
      this.$store.commit("SET_LOADING", true);
      return PropertyUnitsService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
